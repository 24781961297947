import axios from "axios";

const apiUrl = process.env.REACT_APP_SECURITY_BASE_URL_OPEN;

const handleLogin = async (userName, password, endpoint) => {
  // //console.log(`${apiUrl}/${endpoint}`);
  const postData = {
    username: userName,
    password: password,
  };
  try {
    const response = await axios.post(`${apiUrl}/${endpoint}`, postData);
    return response;
  } catch (error) {
    // "Error during login:", error.message;
    throw error; // Rethrow the error
  }
};

const decodeJwtToken = (token) => {
  localStorage.setItem("id", token);
  const base64Url = token.split(".")[1]; // Extract the payload part of the token
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/"); // Convert base64 URL to base64
  const decodedPayload = JSON.parse(atob(base64)); // Decode base64 to JSON
  localStorage.setItem("user_id", decodedPayload.user_id);
  return decodedPayload;
};

export { handleLogin, decodeJwtToken };
