// Settings.js
import React from "react";
import SettingsCard from "./Card/SettingsCard";
import { useApi } from "../../../services/Dropdown/DropdownContext"; // Corrected import path
const Settings = () => {
  const { rootData } = useApi();

  return (
    <>
      <div className="header pb-8  d-flex align-items-center">
        <div className="container-fluid">
          {rootData ? (
            rootData.map((item, index) => (
              <SettingsCard key={index} title={item.name} id={item.id} />
            ))
          ) : (
            <p>Loading...</p>
          )}
        </div>
      </div>
    </>
  );
};

export default Settings;
