import React, { useState } from "react";
import svgIcons from "../../../services/svgService";
import { DeclineArchivedAll } from "../../../services/PendingList/DeclineArchivedApi";
import { toast } from "react-toastify";
import { BounceLoader } from "react-spinners";

const SelectedDecline = ({
  isDeclineAllModalOpen,
  onClose,
  onUpdatePendingList,
  commentMap,
  selectedRows,
}) => {
  const [loading, setLoading] = useState(false);

  if (!isDeclineAllModalOpen) {
    return null;
  }

  // Handler for handling the confirmation button click
  const handleConfirm = () => {
    //console.log(selectedRows);

    // Check if selectedRows is empty
    if (selectedRows.length === 0) {
      // Display a warning toast message
      toast.warning("Please select at least one row.", {
        position: "top-right",
        autoClose: 3000,
      });
      return; // Stop further execution
    }

    // Check if any selected row has no comment
    const hasNoComment = selectedRows.some((row) => !commentMap[row.id]);

    if (hasNoComment) {
      // Display a warning toast message
      toast.warning("Please provide comments for all selected rows.", {
        position: "top-right",
        autoClose: 3000,
      });
      return; // Stop further execution
    }

    const selectedComments = selectedRows.map((row) => ({
      id: row.id,
      comment: commentMap[row.id] || "", // Use empty string if comment is not found
    }));
    const fetchDataFromApi = async () => {
      setLoading(true);

      try {
        // Fetch data from the first API
        const rootResponse = await DeclineArchivedAll(
          selectedComments,
          "DECLINED",
          "expense/bulk_update"
        );

        //console.log(newRootData);
        toast.success(rootResponse.data["message"], {
          position: "top-right",
          autoClose: 3000,
        });
        onUpdatePendingList();
        setLoading(false);
      } catch (error) {
        const errorMessage = error.response?.data?.message;
        if (errorMessage) {
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 3000,
          });
        } else {
          // Handle the error when response or data or message is not available
          toast.error(error.message, {
            position: "top-right",
            autoClose: 3000,
          });
        }
        setLoading(false);
      }
    };
    fetchDataFromApi();

    // Close the modal
    onClose();
  };

  return (
    <>
      {loading && (
        <div className="loader-container">
          <BounceLoader color={"#211e1e1f"} loading={loading} size={150} />
        </div>
      )}
      <div className="modal dropdown-add">
        <div className="modal-card col-md-4 bg-white shadow">
          <div className="modal-card-body p-0">
            <div className="row justify-content-center m-0 sftp-card">
              <div className="col-md-12 p-0">
                <div
                  className="d-flex justify-content-end close-btn"
                  onClick={onClose}
                  dangerouslySetInnerHTML={{ __html: svgIcons.Modalcross }}
                />
              </div>
              <div className="col-md-10 p-0 margin-top">
                <div className="sftp-form">
                  <div className="col-lg-12">
                    <div className="form-group mb-4">
                      <p
                        style={{
                          color: "rgba(73, 83, 106, .7)",
                          textAlign: "center",
                        }}
                      >
                        Are you sure you want to Decline all?
                      </p>
                    </div>
                    <div className="col-lg-12 d-flex justify-content-center">
                      <button
                        style={{
                          width: "120px",
                          color: "rgb(107, 114, 128 ,1)",
                          background: "rgb(255,255,255,1)",
                          border: "1px solid #ccc",
                          padding: "8px",
                        }}
                        className="btn review-btn me-4"
                        onClick={onClose}
                      >
                        No, cancel
                      </button>
                      <button
                        style={{
                          width: "120px",
                          padding: "6px",
                        }}
                        className="btn review-btn"
                        onClick={handleConfirm}
                      >
                        Yes, I'm sure
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectedDecline;
