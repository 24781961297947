import { useState, useMemo, useEffect } from "react";
import svgIcons from "../../../../services/svgService";
import { ArchivedList } from "../../../../services/PendingList/PendingListApi";
import { Modal } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import { toast } from "react-toastify";
import { BounceLoader } from "react-spinners";
import ImageZoomInOut from "../../../../hooks/ImageZoom";
import axios from "axios";

const List = ({ fromDate, toDate }) => {
  const imageUrl = process.env.REACT_APP_IMAGE_STORAGE_PATH;
  const [pending, setPending] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const pageSize = 10;

  const imageFileExtensions = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
    "tiff",
    "tif",
    "webp",
    "svg",
    "psd",
    "raw",
    "heic",
    "ai",
    "eps",
    "ico",
    "xcf",
    "cr2",
    "nef",
    "orf",
    "arw",
    "dng",
  ];

  useEffect(() => {
    const fetchDataFromApi = async () => {
      setLoading(true);
      try {
        // Fetch data from Archived api
        const result = await ArchivedList(
          "expense/filtered-expenses",
          fromDate,
          toDate
        );

        const newData = await Promise.all(
          result.data.result.map(async (user) => {
            // Make an API call to fetch the comment value
            // const idValue = await comment("archived/by-expense", user.id);
            // const comments = idValue.data.result[0]?.comments; // Access comment using optional chaining

            // Check if action_type is not "DELETED"
            if (user.action_type !== "DELETED") {
              return {
                ...user,
                // ID: comments,
              };
            }
          })
        );

        setPending(newData);
        setLoading(false);
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 3000,
        });
        setLoading(false);
      }
    };
    fetchDataFromApi();
  }, [fromDate, toDate]); // Add fromDate and toDate as dependencies to re-run useEffect when they change

  const paginationPageSizeSelector = useMemo(() => {
    return [10, 20, 50];
  }, []);

  const isImageFile = (documentName) => {
    const extension = documentName.split(".").pop().toLowerCase();
    return imageFileExtensions.includes(extension);
  };

  const CustomButtonComponent = (props) => {
    const handleButtonClick = async () => {
      const documentName = props.data["document_name"];

      if (isImageFile(documentName)) {
        setSelectedImage(documentName);
      } else {
        downloadFile(documentName);
      }
      // setLoading(true);
      // try {
      //   const response = await fetchImage(
      //     props.data["document_name"],
      //     "expense/file"
      //   );
      //   const imageUrl = URL.createObjectURL(response);
      //   //console.log(imageUrl);
      //   setSelectedImage(imageUrl);

      //   setLoading(false);
      // } catch (error) {
      //   const errorMessage = error.response?.data?.message;
      //   if (errorMessage) {
      //     toast.error(errorMessage, {
      //       position: "top-right",
      //       autoClose: 3000,
      //     });
      //   } else {
      //     // Handle the error when response or data or message is not available
      //     toast.error(error.message, {
      //       position: "top-right",
      //       autoClose: 3000,
      //     });
      //   }
      //   setLoading(false);
      // }
    };

    // Extracting file extension
    const fileExtension = props.data["document_name"].split(".").pop();
    const fileType = imageFileExtensions.includes(fileExtension)
      ? "image"
      : "document";

    return (
      <div className="pending-list-image" onClick={handleButtonClick}>
        {fileType === "image"
          ? `Image.${fileExtension}`
          : `Document.${fileExtension}`}
      </div>
    );
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const colDefs = [
    {
      field: "user_name",
      headerName: "Employee Name",
      filter: true,
      filterParams: {
        filterPlaceholder: "Search...",
      },
    },

    { field: "type_of_cost.name", headerName: "Expense Type", filter: true },

    {
      field: "id",
      headerName: "id",
      hide: true,
    },
    {
      field: "expense_date",
      headerName: "Expense Date",
      filter: true,
    },

    { field: "status", headerName: "Expense Status", filter: true },
    { field: "currency.name", headerName: "Currency", filter: true },
    {
      field: "total_bill",
      headerName: "Total Bill",
      valueGetter: (params) => `${params.data.total_bill}`,
      filter: true,
    },
    {
      field: "vat_percentage",
      headerName: "VAT % NL",
      filter: true,
      valueGetter: (params) =>
        params.data.vat_percentage != null
          ? `${params.data.vat_percentage}%`
          : "",
    },
    { field: "amount_percentage", headerName: "Amount ex. VAT", filter: true },

    {
      field: "confirmed_amount",
      headerName: "Confirmed Invoice amount (€)",
      valueGetter: (params) => `${params.data.confirmed_amount} €`,
      filter: true,
    },
    {
      field: "document_name",
      headerName: "Attachment",
      cellRenderer: CustomButtonComponent,
    },
    {
      field: "description",
      headerName: "Description",
      filter: true,
    },
    {
      field: "company.name",
      headerName: "Company",
      filter: true,
      filterParams: {
        filterPlaceholder: "Search...",
      },
    },
    {
      field: "vendor",
      headerName: "Customer / Vendor",
      filter: true,
      filterParams: {
        filterPlaceholder: "Search...",
      },
    },
    { field: "payment_method", headerName: "Payment Method", filter: true },
    { field: "card_number", headerName: "Card Number", filter: true },
    { field: "card_type_name", headerName: "Card Type", filter: true },
    { field: "comment", headerName: "Comments", filter: true },
    {
      field: "username",
      headerName: "Employee Email",
      width: "320",
      filter: true,
      filterParams: {
        filterPlaceholder: "Search...",
      },
    },
  ];

  const downloadFile = (filename) => {
    setLoading(true);
    let url = `${imageUrl}/${filename}`;
    // Extract the row ID from the URL
    const rowId = url.split("/").pop();

    // Extract the file extension from the filename
    // const fileExtension = filename.split(".").pop();

    // Construct the new filename with the row ID and the correct file extension
    const newFilename = `${rowId}`;

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = newFilename; // Use the new filename
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // "Error downloading image:", error;
        toast.error(`Error downloading file: ${error.message}`, {
          position: "top-right",
          autoClose: 3000,
        });
      });
  };

  const gridOptions = {
    localeText: {
      noRowsToShow: "No records found.",
    },
  };

  const downloadImage = async (filename) => {
    setLoading(true);

    const url = `${imageUrl}/${filename}`;
    const newFilename = `${filename}`;

    try {
      // Log the URL for debugging purposes
      console.log(`Fetching file from: ${url}`);

      const response = await axios.get(url, {
        responseType: "blob", // Important to set responseType to 'blob'
      });

      // Create a URL for the blob object
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      // Create a link element, set its href, and trigger a click
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = downloadUrl;
      a.download = newFilename;
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(a);
    } catch (error) {
      // Log the error for debugging
      console.error(`Error downloading file: ${error.message}`);
      toast.error(`Error downloading file: ${error.message}`, {
        position: "top-right",
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && (
        <div className="loader-container">
          <BounceLoader color={"#211e1e1f"} loading={loading} size={150} />
        </div>
      )}
      <div className="container-fluid mt-4 mb-5 pending-list">
        <div
          className="m-expense-table-container"
          style={{ paddingBottom: "0.5rem", borderRadius: "6px" }}
        >
          <div
            className="table-wrapper ag-theme-quartz"
            style={{ height: 535 }}
          >
            <AgGridReact
              multiSortKey={"ctrl"}
              rowData={pending}
              columnDefs={colDefs}
              gridOptions={gridOptions}
              pagination={true}
              paginationPageSize={pageSize}
              paginationPageSizeSelector={paginationPageSizeSelector}
              getRowStyle={(params) => {
                return {
                  background:
                    params.node.rowIndex % 2 === 0
                      ? ""
                      : "rgba(245, 245, 245, 1)",
                };
              }}
            />
          </div>
        </div>
      </div>
      <Modal
        show={selectedImage !== null}
        onHide={handleCloseModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="image-container">
          <div className="row" style={{ padding: "18px" }}>
            <div
              className="modal-cross"
              onClick={handleCloseModal}
              dangerouslySetInnerHTML={{ __html: svgIcons.crossDark }}
              title="Close"
            />

            <div
              className="download"
              onClick={() => downloadImage(selectedImage, "attachment.jpg")}
              dangerouslySetInnerHTML={{ __html: svgIcons.download }}
              title="Download"
            />
          </div>
          {/* <div className="row">
            {selectedImage && (
              <div className="magnifier-container">
                <img alt="" src={`${imageUrl}/${selectedImage}`} style={{maxWidth:"100%"}} />
              </div>
            )} */}
          {/* </div> */}
          {selectedImage &&
          imageFileExtensions.includes(
            selectedImage.split(".").pop().toLowerCase()
          ) ? (
            <ImageZoomInOut imageUrl={`${imageUrl}/${selectedImage}`} />
          ) : (
            <div>No preview available for this document type</div>
          )}{" "}
        </div>
      </Modal>
    </>
  );
};

export default List;
