// axiosConfig.js
import axios from "axios";


const logout = () => {
  //console.log("log message"); // Log message
  localStorage.removeItem("id"); // Clear the token from localStorage
  localStorage.removeItem("user_id"); // Clear user_id from localStorage
  localStorage.setItem("isLogged", false);
  // Redirect to /login page
  // window.location.href = "/login";
  if (window.location.pathname !== "/login") {
    // Redirect to /login page
    window.location.href = "/login";
  }
};


const createAxiosInstance = (baseUrl) => {
  const axiosInstance = axios.create({
    baseURL: baseUrl,
  });

  axiosInstance.interceptors.request.use(
    (config) => {
      const accessToken = localStorage.getItem("id");
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const { response } = error;
      if (response && (response.status === 401 || response.status === 403)) {
        logout();
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default createAxiosInstance;
