import React, { useState } from "react";
import Add from "./Add/Add";
import List from "./List/List";

const Employee = () => {
  const [shouldUpdateList, setShouldUpdateList] = useState(false);

  const handleUpdateList = () => {
    setShouldUpdateList(!shouldUpdateList); // Toggle the state to trigger re-render
  };

  return (
    <>
      <div className="header pb-8 py-5 py-lg-8   align-items-center"></div>

      {/* Add Employee */}
      <div className="container-fluid mt-4">
        <div className="card review-card">
          <div className="card-body">
            <div className="row">
              <Add onUpdateList={handleUpdateList} />
            </div>
          </div>
        </div>
      </div>

      {/* Employee list */}
      <List shouldUpdateList={shouldUpdateList} />
    </>
  );
};

export default Employee;
