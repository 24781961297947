import React, { useContext } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Sidebar from "./../CommonComponents/Sidebar/Sidebar";
import routes from "./../../routes";
import { AuthContext } from "../../services/AuthContext";

export default function Admin() {
  const { isLoggedIn } = useContext(AuthContext);

  // If not logged in, redirect to the login page
  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      return (
        <Route path={prop.path} element={prop.component} key={key} exact />
      );
    });
  };

  return (
    <>
      <Sidebar routes={routes} />
      <div className="main-content">
        <Routes>{getRoutes(routes)}</Routes>
      </div>
    </>
  );
}
