import React from "react";
import Employee from "./../LayoutComponents/Employee/Employee";
import AdminNavbar from "../CommonComponents/Navbars/AdminNavbar";

export default function EmployeePage() {
  return (
    <>
      <AdminNavbar />
      <Employee />
    </>
  );
}
