import React, { useState, useEffect } from "react";
import svgIcons from "../../../../services/svgService";
import AddDropdown from "../../../CommonComponents/Modal/AddDropdown";
import { useApi } from "../../../../services/Dropdown/DropdownContext";

const SettingsCard = ({ title, id }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [designations, setDesignations] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { childData, removeDesignation } = useApi();

  useEffect(() => {
    // //console.log(childData);
    const newDesignations = childData.filter(
      (obj) => obj.type_id === id && obj.action_type !== "DELETED"
    );
    setDesignations(newDesignations);
  }, [childData, id]);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleAddDesignation = () => {
    setIsModalOpen(true);
  };

  const add = (newDesignation) => {
    setDesignations([...designations, { name: newDesignation }]);
  };

  const handleRemoveDesignation = async (index, childId) => {
    const updatedDesignations = [...designations];

    await removeDesignation(id, childId);

    setDesignations(updatedDesignations);
  };

  return (
    <>
      <div className="card  settings">
        <div className="card-body" onClick={toggleExpansion}>
          <div className="row">
            <div className="col-md-12 d-flex">
              <div className="settings-card">
                <div className="settings-card-title">{title}</div>
                <div
                  className={`arrow-icon-${isExpanded ? "up" : "down"}`}
                  dangerouslySetInnerHTML={{
                    __html: svgIcons.downArrow,
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {isExpanded && (
          <div className="expanded-row">
            <div className="col-md-12">
              {/* Additional content goes here */}

              {designations.map((designation, index) => (
                <div className="add-btn added-designation" key={index}>
                  <div
                    className="d-flex remove-icon"
                    onClick={() =>
                      handleRemoveDesignation(index, designation.id)
                    }
                    dangerouslySetInnerHTML={{ __html: svgIcons.remove }}
                  ></div>
                  <div className="addded-title">{designation.name}</div>
                </div>
              ))}

              <div className="add-btn plus-add" onClick={handleAddDesignation}>
                <div
                  className="d-flex align-items-center me-2"
                  dangerouslySetInnerHTML={{ __html: svgIcons.plus }}
                ></div>
                <span className="add-title m-0">{`Add new ${title}`}</span>
              </div>
            </div>
          </div>
        )}
      </div>
      <AddDropdown
        isModalOpen={isModalOpen}
        onClose={closeModal}
        parentId={id}
        onAddDesignation={add}
        title={title}
      />
    </>
  );
};

export default SettingsCard;
