import React from "react";
import { createRoot } from "react-dom/client"; // Import createRoot from react-dom/client
import "./assets/scss/main.scss";
import reportWebVitals from "./reportWebVitals";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import Routing from "./components/Routing/Routing";
// import { ApiProvider } from "./services/Dropdown/DropdownContext";
import { AuthProvider } from "./services/AuthContext";

const root = createRoot(document.getElementById("root")); // Use createRoot from react-dom/client
root.render(
  // <ApiProvider>
  <AuthProvider>
    <BrowserRouter>
      <Routing />
      <ToastContainer limit={3} />
    </BrowserRouter>
  </AuthProvider>
  // </ApiProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
