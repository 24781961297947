import { dropdownChild, dropdownroot } from "../Dropdown/DropdownApi";

const filterDropdownChildren = async (dropdownData) => {
  try {
    // Call the initial API to get the list of items
    const rootResponse = await dropdownroot("dropdown/type"); // Replace "endpoint" with your actual endpoint
    // //console.log(rootResponse);

    // Filter out the "Designation" item
    const designationItem = rootResponse.data.result.find(
      (item) => item.name === dropdownData
    );

    if (designationItem) {
      // If "Designation" item is found, call the API to get its details
      const designationResponse = await dropdownChild(
        "dropdown/item/by-type",
        designationItem.id
      ); // Replace "endpoint" with your actual endpoint

      // Filter out the deleted items
      const filteredDesignationOptions = designationResponse.data.result.filter(
        (item) => item.action_type !== "DELETED"
      );

      // //console.log(filteredDesignationOptions);

      // Return filteredDesignationOptions along with other data
      return {
        filteredDesignationOptions,
        rootResponse,
        designationItem,
      };
    }
  } catch (error) {
    // "Error fetching data:", error;
    throw error;
  }
};
export { filterDropdownChildren };
