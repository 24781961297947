import React from "react";
import Pending from "./../LayoutComponents/Pending/Pending";
import AdminNavbar from "../CommonComponents/Navbars/AdminNavbar";

export default function PendingPage() {
  return (
    <>
      <AdminNavbar />
      <Pending />
    </>
  );
}
