import React from "react";
import { Link } from "react-router-dom";

export default function ReviewCard({ waiting }) {
  return (
    <>
      <div className="col-sm-8 d-flex review-title-div">
        <p className="review-title d-flex align-items-center m-0">
          {waiting} Applications are waiting for your review...
        </p>
      </div>
      <div className="col-sm-4 d-flex review-btn-div">
        <Link to="/pending">
          <button className="btn review-btn">Review Now</button>
        </Link>
      </div>
    </>
  );
}
