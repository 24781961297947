import React, { useState, useEffect, forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import svgIcons from "../../../../services/svgService";

const Report = ({ onReportDatesChange }) => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const style = {
    calendarSize: {
      position: "relative",
      top: "-3px",
    },
  };

  useEffect(() => {
    const today = new Date();
    const fromDate = new Date(today);
    fromDate.setMonth(today.getMonth() - 6);

    // Format the dates
    const formattedFromDate = formatDate(fromDate);
    const formattedToDate = formatDate(today);

    // Set the dates
    setFromDate(formattedFromDate);
    setToDate(formattedToDate);
  }, []);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleFromDateChange = (date) => {
    const formattedDate = formatDate(date);
    setFromDate(formattedDate);
  };
  const handleToDateChange = (date) => {
    const formattedDate = formatDate(date);
    setToDate(formattedDate);
  };

  const handleShowReport = () => {
    // Implement your logic for showing the report
    //console.log("From Date:", fromDate);
    //console.log("To Date:", toDate);
    onReportDatesChange(fromDate, toDate); // Pass the dates to the parent component
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} className="custom-input" onClick={onClick}>
      <div className="date-value">{value}</div>
      <div
        className=""
        style={style.calendarSize}
        dangerouslySetInnerHTML={{ __html: svgIcons.calendar }}
      />
    </button>
  ));

  return (
    <>
      <div className="col-lg-4 d-flex">
        <p className="review-title d-flex align-items-center m-0">
          Archived Applications
        </p>
      </div>
      <div className="col-lg-8 d-flex archived-report  mt-2">
        <DatePicker
          selected={fromDate}
          onChange={handleFromDateChange}
          customInput={<CustomInput />}
          dateFormat="dd/MM/yyyy"
          title="From Date"
        />
        <DatePicker
          selected={toDate}
          onChange={handleToDateChange}
          customInput={<CustomInput />}
          dateFormat="dd/MM/yyyy"
          className="me-2"
          title="To Date"
        />

        <button className="btn review-btn" onClick={handleShowReport}>
          Show Report
        </button>
      </div>
    </>
  );
};

export default Report;
