import React from "react";
import Otp from "../LayoutComponents/VerifyOtp/Otp";
import PasswordNavbar from "../CommonComponents/Navbars/PasswordNavbar";

const VerifyOtp = () => {
  return (
    <>
      <PasswordNavbar />
      <Otp />
    </>
  );
};

export default VerifyOtp;
