import React, { useState } from "react";
import svgIcons from "../../../services/svgService";
import { useApi } from "../../../services/Dropdown/DropdownContext";
import { toast } from "react-toastify";

const Modal = ({ isModalOpen, onClose, parentId, onAddDesignation, title }) => {
  const [designationName, setDesignationName] = useState("");
  const [currencyName, setcurrencyName] = useState("");
  const [currencyValue, setcurrencyValue] = useState("");

  const { addDesignation } = useApi();

  const handleInputChange = (e) => {
    setDesignationName(e.target.value);
  };

  const handleInputChangeForCurrencyName = (e) => {
    setcurrencyName(e.target.value);
  };

  const handleInputChangeForCurrencyValue = (e) => {
    setcurrencyValue(e.target.value);
  };

  // Modal.js
  const handleSave = async (e) => {
    e.preventDefault();
    //console.log("Designation Name:", title);

    // Check if either currencyName or currencyValue is empty
    if (title === "Currency" && (currencyValue === "" || currencyName === "")) {
      toast.warning("Please fill all required fields", {
        position: "top-right",
        autoClose: 3000,
      });
      return; // Stop further execution
    }

    onAddDesignation(designationName);

    if (title === "Currency") {
      await addDesignation(parentId, currencyName + " " + currencyValue);
    } else {
      await addDesignation(parentId, designationName);
    }

    setDesignationName("");
    setcurrencyName("");
    setcurrencyValue("");
    onClose();
  };

  if (isModalOpen !== true) {
    return null;
  }

  return (
    <div className="modal dropdown-add">
      <div className="modal-card col-md-4 bg-white shadow">
        <div className="modal-card-body p-0">
          <div className="row justify-content-center m-0 sftp-card">
            <div className="col-md-12 p-0">
              <div
                className="d-flex justify-content-end close-btn"
                onClick={onClose}
                dangerouslySetInnerHTML={{ __html: svgIcons.Modalcross }}
              />
            </div>
            <div className="col-md-10 p-0 margin-top">
              <div className="sftp-form">
                <form action="/" onSubmit={handleSave}>
                  <div className="col-lg-12">
                    <div className="form-group mb-4 d-flex">
                      {title === "Currency" ? (
                        <>
                          <>
                            <input
                              type="text"
                              className="form-control form-input text-background me-2"
                              placeholder="EUR"
                              onChange={handleInputChangeForCurrencyName}
                              onInput={(e) => {
                                e.target.value = e.target.value.slice(0, 3);
                                // Convert lowercase input to uppercase
                                e.target.value = e.target.value.toUpperCase();
                              }}
                              style={{
                                backgroundColor: "rgba(245, 245, 245, 0.8)",
                                border: "none",
                              }}
                              maxLength={3}
                            />
                            <input
                              type="text"
                              className="form-control form-input text-background"
                              placeholder="€"
                              onChange={handleInputChangeForCurrencyValue}
                              style={{
                                backgroundColor: "rgba(245, 245, 245, 0.8)",
                                border: "none",
                              }}
                            />
                          </>
                        </>
                      ) : (
                        <input
                          type="text"
                          className="form-control form-input text-background"
                          placeholder={`${title} name`}
                          onChange={handleInputChange}
                          style={{
                            backgroundColor: "rgba(245, 245, 245, 0.8)",
                            border: "none",
                          }}
                        />
                      )}
                    </div>
                    <div className="col-lg-12">
                      <button className="btn review-btn">Save</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
