import React, { useState } from "react";
import List from "./List/List";
import Report from "./Report/Report";

const Archived = (props) => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const handleReportDates = (startDate, endDate) => {
    setFromDate(startDate);
    setToDate(endDate);
  };

  return (
    <>
      <div className="header pb-8 py-5 py-lg-8  align-items-center"></div>
      {/* Show Report */}
      <div className="container-fluid mt-4">
        <div className="card review-card">
          <div className="card-body">
            <div className="row">
              <Report onReportDatesChange={handleReportDates} />
            </div>
          </div>
        </div>
      </div>
      {/* Pending List */}
      <List fromDate={fromDate} toDate={toDate} />
      {/* Pass fromDate and toDate as props */}
    </>
  );
};

export default Archived;
