import React, { useState, useEffect, useMemo, useCallback } from "react";
import svgIcons from "../../../../services/svgService";
import {
  PendingList,
  // comment,
  confirmedAmount,
} from "../../../../services/PendingList/PendingListApi";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { toast } from "react-toastify";
import { BounceLoader } from "react-spinners";
import { Modal } from "react-bootstrap";
import Decline from "../../../CommonComponents/Modal/Decline";
import EmployeeArchive from "../../../CommonComponents/Modal/EmployeeArchive";
import SelectedArchived from "../../../CommonComponents/Modal/SelectedArchived";
import SelectedDecline from "../../../CommonComponents/Modal/SelectedDecline";
import axios from "axios";

// import {
//   Magnifier,
//   MOUSE_ACTIVATION,
//   TOUCH_ACTIVATION,
// } from "react-image-magnifiers";
import * as XLSX from "xlsx";
import ImageZoomInOut from "../../../../hooks/ImageZoom";

const List = ({ fromDate, toDate, company, employee, payment }) => {
  const imageUrl = process.env.REACT_APP_IMAGE_STORAGE_PATH;
  const [pending, setPending] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState(false);
  const [isArchiveAllModalOpen, setIsArchiveAllModalOpen] = useState(false);
  const [isDeclineAllModalOpen, setDeclineAllModalOpen] = useState(false);
  const [gridApi, setGridApi] = useState(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [commentMap, setCommentMap] = useState({});

  const pageSize = 10;
  const imageFileExtensions = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
    "tiff",
    "tif",
    "webp",
    "svg",
    "psd",
    "raw",
    "heic",
    "ai",
    "eps",
    "ico",
    "xcf",
    "cr2",
    "nef",
    "orf",
    "arw",
    "dng",
  ];

  const handleExportToExcel = () => {
    if (gridApi) {
      const selectedNodes = gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => {
        const rowData = {};
        colDefs.forEach((colDef) => {
          if (!colDef.hide && colDef.field !== "Action") {
            const field = colDef.field.split(".");
            if (field.length > 1) {
              rowData[colDef.headerName] = node.data[field[0]]
                ? node.data[field[0]][field[1]]
                : null;
            } else {
              rowData[colDef.headerName] = node.data[colDef.field];
            }
          }
        });
        return rowData;
      });

      if (selectedData.length === 0) {
        const allData = pending.map((rowData) => {
          const formattedRowData = {};
          colDefs.forEach((colDef) => {
            if (!colDef.hide && colDef.field !== "Action") {
              const field = colDef.field.split(".");
              if (field.length > 1) {
                formattedRowData[colDef.headerName] = rowData[field[0]]
                  ? rowData[field[0]][field[1]]
                  : null;
              } else {
                formattedRowData[colDef.headerName] = rowData[colDef.field];
              }
            }
          });
          return formattedRowData;
        });

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(allData);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        XLSX.writeFile(workbook, "export.xlsx");
      } else {
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(selectedData);
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        XLSX.writeFile(workbook, "export.xlsx");
      }
    }
  };

  const fetchDataFromApi = useCallback(async () => {
    setLoading(true);
    try {
      const result = await PendingList(
        "expense/filter-by",
        "status",
        "SUBMITTED",
        fromDate,
        toDate
      );
      //console.log(result);
      const newData = await Promise.all(
        result.data.result.map(async (user) => {
          // // Make an API call to fetch the comment value
          // const idValue = await comment("archived/by-expense", user.id); // Adjust this line based on your API call
          // const comments = idValue.data.result[0]?.comments; // Access comment using optional chaining

          return {
            ...user,
            // ID: comments,
          };
        })
      );
      console.log(payment);

      const filteredData = newData.filter((item) => {
        // Convert the selected values to lowercase for case-insensitive comparison
        const selectedCompanyLower = company.toLowerCase();
        const selectedEmployeeLower = employee.toLowerCase();
        const selectedPaymentMethodLower = payment.toLowerCase();

        // Convert the item values to lowercase for case-insensitive comparison
        const companyLower = (item.company.name || "").toLowerCase(); // Provide default empty string if company is missing
        const employeeLower = (item.user_name || "").toLowerCase(); // Provide default empty string if user_name is missing
        const paymentMethodLower = (item.card_type_name || "").toLowerCase(); // Provide default empty string if payment_method is missing

        // Check if the item matches the selected company, employee, and payment method
        const companyMatch =
          companyLower === selectedCompanyLower || company === "";
        const employeeMatch =
          employeeLower === selectedEmployeeLower || employee === "";
        const paymentMatch =
          paymentMethodLower === selectedPaymentMethodLower || payment === "";

        // Return true if the item matches all three criteria, otherwise return false
        return (
          companyMatch &&
          employeeMatch &&
          paymentMatch &&
          item.action_type !== "DELETED"
        );
      });

      setPending(filteredData);
      //console.log(newData);
      setLoading(false);
    } catch (error) {
      toast.error(error, {
        position: "top-right",
        autoClose: 3000,
      });
      setLoading(false);
    }
  }, [fromDate, toDate, company, employee, payment]);

  useEffect(() => {
    fetchDataFromApi();
    // //console.log("pending", pending);
  }, [fromDate, toDate, company, employee, payment, fetchDataFromApi]);

  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };

  const handleAddReason = (icon, rowID) => {
    if (icon === "deleteExpense") {
      setIsModalOpen(true);
      setSelectedRowId(rowID);
    }
    if (icon === "right") {
      setIsArchiveModalOpen(true);
      setSelectedRowId(rowID);
    }
  };

  const handleAllDecline = () => {
    setDeclineAllModalOpen(true);
  };

  const handleAllArchived = () => {
    setIsArchiveAllModalOpen(true);
  };

  const ActionIcon = (props) => {
    const icons = ["right", "deleteExpense"];

    // Object to store icon-title mappings
    const iconTitles = {
      right: "Archived",
      deleteExpense: "Declined",
    };

    return (
      <div className="m-expense_logo d-flex w-100 justify-content-evenly">
        {icons.map((icon, index) => (
          <div
            key={index}
            className="cursor-pointer action-icon"
            dangerouslySetInnerHTML={{ __html: svgIcons[icon] }}
            title={iconTitles[icon]}
            onClick={() => handleAddReason(icon, props.data["id"])}
          />
        ))}
      </div>
    );
  };

  const isImageFile = (documentName) => {
    const extension = documentName.split(".").pop().toLowerCase();
    return imageFileExtensions.includes(extension);
  };

  const CustomButtonComponent = (props) => {
    const handleButtonClick = async () => {
      // setLoading(true);
      // //console.log(props.data["document_name"]);
      // try {
      //   const response = await fetchImage(
      //     props.data["document_name"],
      //     "expense/file"
      //   );
      //   //console.log(response);
      //   const imageUrl = URL.createObjectURL(response);
      //   //console.log(imageUrl);
      //   setSelectedImage(imageUrl);
      //   setLoading(false);
      // } catch (error) {
      //   toast.error(error.message, {
      //     position: "top-right",
      //     autoClose: 3000,
      //   });
      //   setLoading(false);
      // }
      const documentName = props.data["document_name"];
      console.log("document", documentName);

      if (isImageFile(documentName)) {
        setSelectedImage(documentName);
      } else {
        downloadFile(documentName);
      }
    };

    // Extracting file extension
    const fileExtension = props.data["document_name"].split(".").pop();
    const fileType = imageFileExtensions.includes(fileExtension)
      ? "image"
      : "document";

    return (
      <div className="pending-list-image" onClick={handleButtonClick}>
        {fileType === "image"
          ? `Image.${fileExtension}`
          : `Document.${fileExtension}`}
      </div>
    );
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const handleUpdateComment = (rowId, newComment) => {
    //console.log(newComment);

    // Function to update comment for a specific row ID
    setCommentMap((prevMap) => ({
      ...prevMap,
      [rowId]: newComment,
    }));
  };

  const confirmAmountAPI = (id, amount) => {
    //console.log(amount, id);
    const amountApi = async () => {
      setLoading(true);

      try {
        // Fetch data from the first API
        const rootResponse = await confirmedAmount(
          amount,
          id,
          "expense/confirmed_amount"
        );

        //console.log(newRootData);
        toast.success(rootResponse.data["message"], {
          position: "top-right",
          autoClose: 3000,
        });
        setLoading(false);
        fetchDataFromApi();
      } catch (error) {
        //console.log(error);
        toast.error(error.message, {
          position: "top-right",
          autoClose: 3000,
        });
        setLoading(false);
      }
    };
    amountApi();
  };

  const colDefs = [
    {
      field: "user_name",
      headerName: "Employee Name",
      headerCheckboxSelection: true,
      headerCheckboxSelectionCurrentPageOnly: true,
      checkboxSelection: true,
      filter: true,
      filterParams: {
        filterPlaceholder: "Search...",
      },
    },
    {
      field: "expense_date",
      headerName: "Expense Date",
      filter: true,
    },
    { field: "type_of_cost.name", headerName: "Expense Type", filter: true },
    { field: "currency.name", headerName: "Currency", hide: true },
    {
      field: "total_euro",
      headerName: "Total Bill",
      hide: true,
      valueGetter: (params) => `${params.data.total_bill}`,
    },
    {
      field: "vat_percentage",
      headerName: "VAT % NL",
      filter: true,
      valueGetter: (params) =>
        params.data.vat_percentage != null
          ? `${params.data.vat_percentage}%`
          : "",
    },
    { field: "amount_percentage", headerName: "Amount ex. VAT", filter: true },
    {
      field: "confirmed_amount",
      headerName: "Confirmed Invoice amount (€)",
      filter: true,
      valueGetter: (params) => {
        // Check if confirmed_amount is "0.00"
        if (params.data.confirmed_amount === "0.00") {
          // Return the value of total_euro
          return `${params.data.total_euro} €`;
        }
        // Otherwise, return the value of confirmed_amount
        return `${params.data.confirmed_amount}`;
      },

      editable: true,
      onCellValueChanged: (event) => {
        //console.log("Cell value changed:", event.data.id, event.newValue);
        confirmAmountAPI(event.data.id, event.newValue);
      },
    },
    {
      field: "document_name",
      headerName: "Attachment",
      cellRenderer: CustomButtonComponent,
    },
    {
      field: "description",
      headerName: "Description",
      filter: true,
    },
    {
      field: "company.name",
      headerName: "Company",
      filter: true,
      filterParams: {
        filterPlaceholder: "Search...",
      },
    },
    {
      field: "id",
      headerName: "id",
      hide: true,
    },

    {
      field: "vendor",
      headerName: "Customer / Vendor",
      filter: true,
      hide: true,
      filterParams: {
        filterPlaceholder: "Search...",
      },
    },

    { field: "payment_method", headerName: "Payment Method", hide: true },
    { field: "card_number", headerName: "Card Number", filter: true },
    { field: "card_type_name", headerName: "Card Type", filter: true },
    {
      field: "comment",
      headerName: "Comments",
      editable: true,
      onCellValueChanged: (event) => {
        //console.log("Cell value changed:", event.data.id, event.newValue);
        handleUpdateComment(event.data.id, event.newValue); // Call handleUpdateComment with rowId and updated comment
      },
    },
    {
      field: "username",
      headerName: "Employee Email",
      filter: true,
      hide: true,
      filterParams: {
        filterPlaceholder: "Search...",
      },
    },
    {
      field: "Action",
      headerName: "Action",
      cellRenderer: ActionIcon,
    },
  ];

  const gridOptions = {
    localeText: {
      noRowsToShow: "No records found.",
    },
    onSelectionChanged: onSelectionChanged,
  };

  const paginationPageSizeSelector = useMemo(() => {
    return [10, 20, 50];
  }, []);

  function onGridReady(params) {
    setGridApi(params.api);
  }

  function onSelectionChanged() {
    if (gridApi) {
      const selectedNodes = gridApi.getSelectedNodes();
      const selectedData = selectedNodes.map((node) => node.data);
      // console.log(selectedData);
      setSelectedRows(selectedData);
    }
  }

  const downloadImage = async (filename) => {
    setLoading(true);

    const url = `${imageUrl}/${filename}`;
    const newFilename = `${filename}`;

    try {
      // Log the URL for debugging purposes
      console.log(`Fetching file from: ${url}`);

      const response = await axios.get(url, {
        responseType: "blob", // Important to set responseType to 'blob'
      });

      // Create a URL for the blob object
      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));

      // Create a link element, set its href, and trigger a click
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = downloadUrl;
      a.download = newFilename;
      document.body.appendChild(a);
      a.click();

      // Clean up
      window.URL.revokeObjectURL(downloadUrl);
      document.body.removeChild(a);
    } catch (error) {
      // Log the error for debugging
      console.error(`Error downloading file: ${error.message}`);
      toast.error(`Error downloading file: ${error.message}`, {
        position: "top-right",
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const downloadFile = (filename) => {
    setLoading(true);
    let url = `${imageUrl}/${filename}`;
    // Extract the row ID from the URL
    const rowId = url.split("/").pop();

    // Extract the file extension from the filename
    // const fileExtension = filename.split(".").pop();

    // Construct the new filename with the row ID and the correct file extension
    const newFilename = `${rowId}`;

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = newFilename; // Use the new filename
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        // "Error downloading image:", error;
        toast.error(`Error downloading file: ${error.message}`, {
          position: "top-right",
          autoClose: 3000,
        });
      });
  };

  const handleUpdatePendingList = () => {
    // //console.log("updated pending list");
    fetchDataFromApi();
  };

  return (
    <>
      {loading && (
        <div className="loader-container">
          <BounceLoader color={"#211e1e1f"} loading={loading} size={150} />
        </div>
      )}
      <div className="container-fluid mt-4 mb-5 ">
        <div className="m-expense-table-container pending-list employee-table">
          <div
            className="table-wrapper ag-theme-quartz"
            style={{ height: 535 }}
          >
            <AgGridReact
              multiSortKey={"ctrl"}
              rowData={pending}
              gridOptions={gridOptions}
              columnDefs={colDefs}
              rowSelection={"multiple"}
              pagination={true}
              paginationPageSize={pageSize}
              paginationPageSizeSelector={paginationPageSizeSelector}
              getRowStyle={(params) => {
                return {
                  background:
                    params.node.rowIndex % 2 === 0
                      ? ""
                      : "rgba(245, 245, 245, 1)",
                };
              }}
              onGridReady={onGridReady}
              onSelectionChanged={onSelectionChanged}
            />
          </div>
        </div>
        <div className="col-md-12 excel-section">
          <div className="card">
            <div className="card-body review-card">
              <div className="row">
                <div className="col-sm-4 d-flex align-items-center">
                  <div className="select-title d-flex">
                    <p className="review-title m-0">
                      Selected files({selectedRows.length})
                    </p>
                  </div>
                </div>
                <div className="col-sm-8  d-flex add-employee mt-2">
                  <div className="excel-btn d-flex">
                    <button className="btn me-3" onClick={handleExportToExcel}>
                      Export to Excel
                    </button>
                    <button className="btn me-3" onClick={handleAllDecline}>
                      Decline
                    </button>
                    <button className="btn" onClick={handleAllArchived}>
                      Archive Selected
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={selectedImage !== null}
        onHide={handleCloseModal}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="image-container">
          <div className="row" style={{ padding: "18px" }}>
            <div
              className="modal-cross"
              onClick={handleCloseModal}
              dangerouslySetInnerHTML={{ __html: svgIcons.crossDark }}
              title="Close"
            />

            <div
              className="download"
              onClick={() => downloadImage(selectedImage, "attachment.jpg")}
              dangerouslySetInnerHTML={{ __html: svgIcons.download }}
              title="Download"
            />
          </div>
          {/* <div className="row">
            {selectedImage && (
              <div className="magnifier-container">
                <img alt="" src={`${imageUrl}/${selectedImage}`} style={{maxWidth:"100%"}} />
              </div>
            )} */}
          {/* </div> */}
          {selectedImage &&
          imageFileExtensions.includes(
            selectedImage.split(".").pop().toLowerCase()
          ) ? (
            <ImageZoomInOut imageUrl={`${imageUrl}/${selectedImage}`} />
          ) : (
            <div>No preview available for this document type</div>
          )}{" "}
        </div>
      </Modal>
      <Decline
        isModalOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedRowId(null); // Reset selected row ID when modal is closed
        }}
        rowId={selectedRowId} // Pass selected row ID to the Decline component
        onUpdatePendingList={handleUpdatePendingList}
        comment={commentMap[selectedRowId] || ""}
      />
      <EmployeeArchive
        isArchiveModalOpen={isArchiveModalOpen}
        onClose={() => {
          setIsArchiveModalOpen(false);
          setSelectedRowId(null); // Reset selected row ID when modal is closed
        }}
        rowId={selectedRowId} // Pass selected row ID to the Decline component
        onUpdatePendingList={handleUpdatePendingList}
        comment={commentMap[selectedRowId] || ""}
      />
      <SelectedArchived
        isArchiveAllModalOpen={isArchiveAllModalOpen}
        onClose={() => {
          setIsArchiveAllModalOpen(false);
        }}
        onUpdatePendingList={handleUpdatePendingList}
        commentMap={commentMap}
        selectedRows={selectedRows} // Pass the selected rows
      />
      <SelectedDecline
        isDeclineAllModalOpen={isDeclineAllModalOpen}
        onClose={() => {
          setDeclineAllModalOpen(false);
        }}
        commentMap={commentMap}
        onUpdatePendingList={handleUpdatePendingList}
        selectedRows={selectedRows} // Pass the selected rows
      />
    </>
  );
};
export default List;
