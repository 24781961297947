import React from "react";
import svgIcons from "../../../services/svgService";
// reactstrap components
import { Navbar } from "reactstrap";
import { Link } from "react-router-dom";

export default function PasswordNavbar() {
  return (
    <>
      <Navbar
        className="navbar-top navbar-dark password-navbar"
        expand="md"
        id="navbar-main"
      >
        <div
          className=" align-items-center  d-flex w-100 justify-content-between"
          navbar
        >
          <div
            className="m-expense_logo"
            dangerouslySetInnerHTML={{ __html: svgIcons.logo }}
          />
          <Link className="login" to="/login">
            Log in
          </Link>
        </div>
      </Navbar>
    </>
  );
}
