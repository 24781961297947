import React, { useState, useEffect, forwardRef } from "react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  Label,
  ResponsiveContainer,
} from "recharts";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import svgIcons from "../../../../services/svgService";

const height = 300;
// const width = 300;

const gradProps = {
  gradientUnits: "userSpaceOnUse",
  x1: "0",
  y1: "0",
  x2: "0",
  y2: height,
};

const style = {
  chartSize: {
    position: "relative",
    top: "-12px",
  },
  calendarSize: {
    position: "relative",
    top: "-13px",
  },
};

const Chart = ({ data, fromDate, toDate, handleDateChange }) => {
  // State variables for fromDate and toDate
  //console.log(fromDate, toDate);
  const [startDate, setStartDate] = useState(fromDate);
  const [endDate, setEndDate] = useState(toDate);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleFromDateChange = (date) => {
    const formattedDate = formatDate(date);
    setStartDate(formattedDate);
    handleDateChange("fromDate", formattedDate);
  };

  const handleToDateChange = (date) => {
    const formattedDate = formatDate(date);
    setEndDate(formattedDate);
    handleDateChange("toDate", formattedDate);
  };

  // Effect to set default values for fromDate and toDate on initial render
  useEffect(() => {
    if (!fromDate || !toDate) {
      const today = new Date();
      const sixMonthsAgo = new Date(today);
      sixMonthsAgo.setMonth(today.getMonth() - 6);

      const formattedFromDate = sixMonthsAgo.toISOString().slice(0, 10); // Format to yyyy-mm-dd
      const formattedToDate = today.toISOString().slice(0, 10); // Format to yyyy-mm-dd

      if (!fromDate) {
        setStartDate(formattedFromDate);
        handleDateChange("fromDate", formattedFromDate);
      }

      if (!toDate) {
        setEndDate(formattedToDate);
        handleDateChange("toDate", formattedToDate);
      }
    }
  }, [fromDate, handleDateChange, toDate]);

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} className="custom-input" onClick={onClick}>
      <div className="date-value" style={style.chartSize}>
        {value}
      </div>
      <div
        className=""
        style={style.calendarSize}
        dangerouslySetInnerHTML={{ __html: svgIcons.calendar }}
      />
    </button>
  ));

  return (
    <div className="chart-container">
      <div className="chart-inputs">
        <p className="chart-title">Monthly Total Expenses</p>
        {/* Date inputs */}
        <span className="from">From</span>

        <div className=" chart-input col-md-4" style={{ width: "auto" }}>
          <DatePicker
            selected={startDate}
            onChange={handleFromDateChange}
            customInput={<CustomInput />}
            dateFormat="dd/MM/yyyy"
            title="From Date"
          />
        </div>

        <span className="to">To</span>
        <div className="chart-input-to col-md-4" style={{ width: "auto" }}>
          <DatePicker
            selected={endDate}
            onChange={handleToDateChange}
            customInput={<CustomInput />}
            dateFormat="dd/MM/yyyy"
            className="me-2"
            title="To Date"
          />
        </div>
      </div>
      <ResponsiveContainer width="100%" height={500}>
        <Label
          value="Monthly Amounts"
          position="top"
          offset={10}
          style={{ fontSize: 16, fontWeight: "bold" }}
        />
        <AreaChart
          data={data}
          margin={{
            top: 10,
            right: 30,
            left: 0,
            bottom: 0,
          }}
        >
          <defs>
            <linearGradient id="gradient" {...gradProps}>
              <stop offset="25%" stopColor="rgba(128,213,243,1)" />
              <stop offset="100%" stopColor="rgba(255,255,255,0)" />
            </linearGradient>
          </defs>
          <XAxis dataKey="month" />
          <YAxis />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="total_euro"
            stroke="rgba(128,213,243,1)"
            fill="url(#gradient)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
