import Card from "./Card/DashboardCard";
import React, { useState, useEffect } from "react";
import ReviewCard from "./ReviewCard/ReviewCard";
import svgIcons from "../../../services/svgService";
import Chart from "./Chart/Chart";
import Dropdown from "./Dropdown/Dropdown";
import { dashboardData } from "../../../services/Dashboard/DashboardApi";
import { toast } from "react-toastify";

const Dashboard = () => {
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [chartData, setchartData] = useState([]);
  const [totalApplication, setApplication] = useState("");
  const [totalArchived, setArchived] = useState("");
  const [totalWaitingApplication, setWaitingApplication] = useState("");
  const [totalDeclined, setTotalDeclined] = useState("");

  // const [selectedOption, setSelectedOption] = useState(""); // Initialize selected option state
  // const [selectName, setselectName] = useState(""); // Initialize selected option state
  const [selectedOptions, setSelectedOptions] = useState({
    Employee: "all",
    Company: "all",
    "Expense Type": "all",
  });

  // Callback function to receive selected option data from Dropdown component
  const handleOptionChange = (name, selectedData) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [name]: selectedData,
    }));
  };

  const handleDateChange = (name, selectedData) => {
    if (name === "fromDate") {
      setSelectedFromDate(selectedData);
    } else if (name === "toDate") {
      setSelectedToDate(selectedData);
    }
  };

  useEffect(() => {
    // Dashboard api integration
    const fetchData = async () => {
      try {
        // Company
        const response = await dashboardData(
          selectedOptions,
          "dashboard/dashboard-data",
          selectedFromDate,
          selectedToDate
        );
        // //console.log(response.data.result.line_chart_data);
        setchartData(response.data.result.line_chart_data);
        setApplication(response.data.result.total_application_without_saved);
        setArchived(response.data.result.total_archived);
        setWaitingApplication(response.data.result.total_waiting_application);
        setTotalDeclined(response.data.result.total_declined);
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 3000,
        });
      }
    };
    fetchData();
  }, [selectedOptions, selectedFromDate, selectedToDate]);

  return (
    <>
      <div className="header pb-8 py-5 py-lg-8 align-items-center"></div>
      {/* Review */}
      <div className="container-fluid review-dashboard">
        <div className="row">
          <div className=" col-sm-6  card-container col-md-6">
            <div className="card-body select-card review review-card">
              <div className="row">
                <ReviewCard waiting={totalWaitingApplication} />
              </div>
            </div>
          </div>
          <div className=" col-sm-6 card-container col-md-6">
            <div className="card-body select-card review-card">
              <div className="row">
                <Dropdown name="Employee" onOptionChange={handleOptionChange} />
                <Dropdown name="Company" onOptionChange={handleOptionChange} />
                <Dropdown
                  name="Expense Type"
                  onOptionChange={handleOptionChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Dashboard Card */}
      <div className="container-fluid  mt-4">
        <div className="row">
          <Card
            svg={svgIcons.dashboardApplication}
            cardNumber={totalApplication}
            cardTitle="Total Application"
          />
          <Card
            svg={svgIcons.dashboardArchived}
            cardNumber={totalArchived}
            cardTitle="Total Archived"
          />
          <Card
            svg={svgIcons.dashboardDeclined}
            cardNumber={totalDeclined}
            cardTitle="Total Declined"
          />
        </div>
      </div>
      {/* Chart */}
      <Chart
        data={chartData}
        fromDate={selectedFromDate}
        toDate={selectedToDate}
        handleDateChange={handleDateChange}
      />
    </>
  );
};

export default Dashboard;
