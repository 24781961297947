import React from "react";
import DropDown from "../DropDown/DropDown";
// import svgIcons from "../../../services/svgService";
// reactstrap components
import {
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Media,
} from "reactstrap";
import { decodeJwtToken } from "../../../services/LogIn/LogInApi";

export default function AdminNavbar() {
  const token = localStorage.getItem("id");
  let username;
  if (token) {
    const decodedToken = decodeJwtToken(token);
    username = decodedToken.first_name + " " + decodedToken.last_name;
  }

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Nav className="align-items-center d-none d-md-flex" navbar>
          {/* <div className="page-">Pending list</div> */}
          <div className="me-2 mb-2 d-flex noti">{username}</div>
          <UncontrolledDropdown nav>
            <DropdownToggle className="pr-0" nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <img
                    alt="..."
                    src={require("../../../assets/images/user.png")}
                  />
                </span>
              </Media>
            </DropdownToggle>
            <DropDown></DropDown>
          </UncontrolledDropdown>
        </Nav>
      </Navbar>
    </>
  );
}
