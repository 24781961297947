import React, { useState, useEffect, forwardRef } from "react";
import { filterDropdownChildren } from "../../../../services/Dropdown/DropdownFilter";
import { toast } from "react-toastify";
import { AllList } from "../../../../services/Employee/EmployeeListApi";
import svgIcons from "../../../../services/svgService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Report = ({ onReportDatesChange }) => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [companyOptions, setCompanyOptions] = useState([]);
  const [paymentOptions, setpaymentOptions] = useState([]);
  const [employeeOptions, setemployeeOptions] = useState([]);

  const [selectedCompany, setSelectedCompany] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState("");

  const style = {
    calendarSize: {
      position: "relative",
      top: "-3px",
    },
  };

  useEffect(() => {
    const today = new Date();
    const fromDate = new Date(today);
    fromDate.setMonth(today.getMonth() - 6);

    // Format the dates
    const formattedFromDate = formatDate(fromDate);
    const formattedToDate = formatDate(today);

    // Set the dates
    setFromDate(formattedFromDate);
    setToDate(formattedToDate);

    // Dropdown api integration
    const fetchData = async () => {
      try {
        // Company
        const { filteredDesignationOptions } = await filterDropdownChildren(
          "Company"
        );
        setCompanyOptions(filteredDesignationOptions);

        // Payment method
        const response = await filterDropdownChildren("Card Type");
        setpaymentOptions(response.filteredDesignationOptions);

        // Employee
        const result = await AllList("users");
        const filteredEmployee = result.data.result.filter(
          (item) => item.action_type !== "DELETED"
        );
        setemployeeOptions(filteredEmployee);
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 3000,
        });
      }
    };
    fetchData();
  }, []);

  const handleCompanyChange = (event) => {
    setSelectedCompany(event.target.value);
    // //console.log(event.target.value);
  };

  const handleEmployeeChange = (event) => {
    setSelectedEmployee(event.target.value);
    // //console.log(event.target.value);
  };

  const handlePaymentMethodChange = (event) => {
    setSelectedPaymentMethod(event.target.value);
    // //console.log(event.target.value);
  };

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleFromDateChange = (date) => {
    const formattedDate = formatDate(date);
    setFromDate(formattedDate);
  };
  const handleToDateChange = (date) => {
    const formattedDate = formatDate(date);
    setToDate(formattedDate);
  };

  const handleShowReport = () => {
    // //console.log("From Date:", fromDate);
    // //console.log("To Date:", toDate);
    onReportDatesChange(
      fromDate,
      toDate,
      selectedCompany,
      selectedEmployee,
      selectedPaymentMethod
    );
    // Pass the dates to the parent component
  };

  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <button ref={ref} className="custom-input" onClick={onClick}>
      <div className="date-value">{value}</div>
      <div
        className=""
        style={style.calendarSize}
        dangerouslySetInnerHTML={{ __html: svgIcons.calendar }}
      />
    </button>
  ));

  const selectStyles = {
    padding: "12px",
    color: "rgba(73, 83, 106, 0.7)",
    border: "none",
  };

  return (
    <>
      <div className="col-xl-6 d-flex pending-report pending-report-first">
        <div className="form-group pending-form me-1 w-100">
          <select
            name="company"
            className="form-control-alternative pending-dropdown"
            placeholder="Company"
            style={selectStyles}
            value={selectedCompany}
            onChange={handleCompanyChange}
          >
            <option value="">All Company</option>
            {companyOptions &&
              companyOptions.map((option) => (
                <option key={option.id} value={option.name}>
                  {option.name}
                </option>
              ))}
          </select>
        </div>
        <div className="form-group  pending-form me-1 w-100">
          <select
            name="employee"
            className="form-control-alternative pending-dropdown "
            placeholder="Employee"
            style={selectStyles}
            value={selectedEmployee}
            onChange={handleEmployeeChange}
          >
            <option value="">All Employee</option>
            {employeeOptions &&
              employeeOptions.map(
                (option) =>
                  option.first_name &&
                  option.last_name && (
                    <option
                      key={option.id}
                      value={option.first_name + " " + option.last_name}
                    >
                      {option.first_name} {option.last_name}
                    </option>
                  )
              )}
          </select>
        </div>
        <div className="form-group  pending-form w-100">
          <select
            name="Payment method"
            className="form-control-alternative pending-dropdown"
            placeholder="Payment method"
            style={selectStyles}
            value={selectedPaymentMethod}
            onChange={handlePaymentMethodChange}
          >
            <option value="">All Payment Method</option>
            {paymentOptions &&
              paymentOptions.map((option) => (
                <option key={option.id} value={option.name}>
                  {option.name}
                </option>
              ))}
          </select>
        </div>
      </div>
      <div className="col-xl-6 d-flex pending-report add-employee">
        <DatePicker
          selected={fromDate}
          onChange={handleFromDateChange}
          customInput={<CustomInput />}
          dateFormat="dd/MM/yyyy"
          title="From Date"
        />
        <DatePicker
          selected={toDate}
          onChange={handleToDateChange}
          customInput={<CustomInput />}
          dateFormat="dd/MM/yyyy"
          className="me-2"
          title="To Date"
        />
        <button
          className="btn review-btn w-90  pending-form"
          onClick={handleShowReport}
        >
          Show Report
        </button>
      </div>
    </>
  );
};

export default Report;
