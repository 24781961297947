import React, { useState, useEffect } from "react";
import svgIcons from "../../../services/svgService";
import {
  getRole,
  Addemployee,
} from "../../../services/Employee/EmployeeListApi";
import { toast } from "react-toastify";
import { BounceLoader } from "react-spinners";
import { filterDropdownChildren } from "../../../services/Dropdown/DropdownFilter";
import Response from "./Response";

const Modal = ({ isModalOpen, onClose, onUpdateList }) => {
  const [role, setRole] = useState([]);
  const [designationOptions, setDesignationOptions] = useState([]);
  const [isResponseModalOpen, setIsResponseModalOpen] = useState(false);
  const [success, setSuccess] = useState(false); // Track API call success

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    designation: 0,
    role: "",
    email: "",
  });

  const openResponseModal = () => {
    setIsResponseModalOpen(true);
  };

  const closeResponseModal = () => {
    setIsResponseModalOpen(false);
  };

  const closeAllModals = () => {
    onClose(); // Close the current modal
    closeResponseModal(); // Close the response modal
  };

  // Function to handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    // //console.log(formData);
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if any of the required fields are empty
    const requiredFields = [
      "firstName",
      "lastName",
      "designation",
      "role",
      "email",
    ];
    const emptyFields = requiredFields.filter((field) => !formData[field]);

    // Check email format using regular expression
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      toast.warning("Please enter a valid email address.", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    // If any required field is empty, show a warning toast and return
    if (emptyFields.length > 0) {
      toast.warning("Please fill all required fields.", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    try {
      setLoading(true);
      // Call the API
      const response = await Addemployee(
        formData.firstName,
        formData.lastName,
        formData.designation,
        formData.email,
        formData.role,
        "users"
      );
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 3000,
      });
      //console.log("API response:", response);

      // Reset the form data
      setFormData({
        firstName: "",
        lastName: "",
        designation: 0,
        role: "",
        email: "",
      });

      setLoading(false);
      // onClose(); // Close the modal
      setSuccess(true); // Set success state to true
      onUpdateList(); // Call the callback function passed from the parent to trigger re-fetching of the employee list
    } catch (error) {
      console.error("Error posting data:", error.message); 
      setLoading(false);
      toast.error(error.message, {
        position: "top-right",
        autoClose: 3000,
      });
      setSuccess(false);
    } finally {
      openResponseModal();
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getRole("roles");
        //console.log(response);
        setRole(response.data.result);
      } catch (error) {
        //console.error("Error fetching data:", error);
      }

      try {
        const { filteredDesignationOptions } = await filterDropdownChildren(
          "Designation"
        );
        setDesignationOptions(filteredDesignationOptions);
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 3000,
        });
      }
    };

    if (isModalOpen) {
      fetchData();
    }
  }, [isModalOpen]);

  if (!isModalOpen) {
    return null;
  }

  return (
    <>
      {loading && (
        <div className="loader-container" style={{ width: "auto" }}>
          <BounceLoader color={"#211e1e1f"} loading={loading} size={150} />
        </div>
      )}
      <div className="modal dropdown-add">
        <div className="modal-card add-modal-card col-md-4 bg-white shadow">
          <div className="modal-card-body p-0">
            <div className="row justify-content-center m-0 sftp-card">
              <div className="col-md-12 p-0">
                <div
                  className="d-flex justify-content-end close-btn"
                  onClick={onClose}
                  dangerouslySetInnerHTML={{ __html: svgIcons.Modalcross }}
                />
              </div>
              <div className="col-md-10 p-0 margin-top">
                <div className="sftp-form">
                  <div className="col-lg-12">
                    <div className="form-group mb-4">
                      <p
                        style={{
                          color: "rgba(73, 83, 106, .7)",
                        }}
                      >
                        Edit employee details
                      </p>

                      <form onSubmit={handleSubmit}>
                        <div className="row">
                          <div className="col-lg-6 mb-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control-alternative"
                                placeholder="First Name"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 mb-4">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control-alternative"
                                placeholder="Last Name"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <select
                                name="designation"
                                className="form-control-alternative"
                                placeholder="Designation"
                                value={formData.designation}
                                onChange={handleInputChange}
                              >
                                <option value="">Designation</option>
                                {designationOptions &&
                                  designationOptions.map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <select
                                name="role"
                                className="form-control-alternative"
                                placeholder="Role"
                                value={formData.role}
                                onChange={handleInputChange}
                              >
                                <option value="">Role</option>
                                {role &&
                                  role.map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="form-group">
                              <input
                                type="email"
                                className="form-control-alternative"
                                placeholder="Email address"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <button type="submit" className="btn review-btn">
                              Invite
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isResponseModalOpen && (
        <Response
          isResponseModalOpen={isResponseModalOpen}
          closeModal={closeResponseModal}
          closeAllModals={closeAllModals}
          success={success} // Pass the success state to the Response component
        />
      )}
    </>
  );
};

export default Modal;
