import { useState, useEffect } from "react";
import svgIcons from "../../../services/svgService";
import { getRole } from "../../../services/Employee/EmployeeListApi";
import { toast } from "react-toastify";
import { BounceLoader } from "react-spinners";
import { updateEmployee } from "../../../services/Employee/EmployeeListApi";

const Update = ({
  isModalOpen,
  onClose,
  rowId,
  onUpdateEmployeeList,
  firstName,
  lastName,
  roles,
}) => {
  const [role, setRole] = useState([]);
  // const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: firstName,
    lastName: lastName,
    role: roles?.id,
  });

  useEffect(() => {
    console.log(roles);
    setFormData({
      firstName: firstName,
      lastName: lastName,
      role:roles?.id
    });
    const fetchData = async () => {
      // setLoading(true);
      try {
        const response = await getRole("roles");
        //console.log(response);
        setRole(response.data.result);
        // setLoading(false);
      } catch (error) {
        const errorMessage = error.response?.data?.message;
        if (errorMessage) {
          toast.error(errorMessage, {
            position: "top-right",
            autoClose: 3000,
          });
        } else {
          // Handle the error when response or data or message is not available
          toast.error(error.message, {
            position: "top-right",
            autoClose: 3000,
          });
        }
        // setLoading(false);
      }
    };

    if (isModalOpen) {
      fetchData();
    }
  }, [isModalOpen, firstName, lastName,roles]);

  if (!isModalOpen) {
    return null;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleUpdate = async () => {
    // Check if any required field is empty
    if (!formData.firstName || !formData.lastName || !formData.role) {
      toast.warning("Please fill all required fields", {
        position: "top-right",
        autoClose: 3000,
      });
      return; // Stop execution if any field is empty
    }

    try {
      const result = await updateEmployee(rowId, formData, "users");
      toast.success(result.data.message, {
        position: "top-right",
        autoClose: 3000,
      }); // You may want to close the modal here

      onUpdateEmployeeList();
      onClose();
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      if (errorMessage) {
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
        });
      } else {
        // Handle the error when response or data or message is not available
        toast.error(error.message, {
          position: "top-right",
          autoClose: 3000,
        });
      }
    }
  };

  return (
    <>
      {/* {loading && (
        <div className="loader-container">
          <BounceLoader color={"#211e1e1f"} loading={loading} size={150} />
        </div>
      )} */}
      <div className="modal dropdown-add">
        {/* Modal content */}
        <div className="modal-card col-md-4 bg-white shadow">
          {/* Modal body */}
          <div className="modal-card-body p-0">
            <div className="row justify-content-center m-0 sftp-card">
              <div className="col-md-12 p-0">
                <div
                  className="d-flex justify-content-end close-btn"
                  onClick={onClose}
                  dangerouslySetInnerHTML={{ __html: svgIcons.Modalcross }}
                />
              </div>
              <div className="col-md-10 p-0 margin-top">
                <div className="sftp-form">
                  <div className="col-lg-12">
                    <div className="form-group mb-4">
                      <p
                        style={{
                          color: "rgba(73, 83, 106, .7)",
                        }}
                      >
                        Edit employee details
                      </p>
                      <form>
                        <div className="row">
                          <div className="col-lg-6 mb-3">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control form-control-alternative first-name-input"
                                placeholder="First Name"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>
                          <div className="col-lg-6 mb-3">
                            <div className="form-group">
                              <input
                                type="text"
                                className="form-control form-control-alternative"
                                placeholder="Last Name"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleInputChange}
                              />
                            </div>
                          </div>

                          <div className="col-lg-12 mb-0">
                            <div className="form-group">
                              <select
                                name="role"
                                className="form-control-alternative"
                                placeholder="Role"
                                value={formData.role}
                                onChange={handleInputChange}
                              >
                                <option value="">Role</option>
                                {role &&
                                  role.map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="col-lg-12">
                      <button className="btn review-btn" onClick={handleUpdate}>
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Update;
