import createAxiosInstance from "../axiosConfig";
const apiUrl = process.env.REACT_APP_MAIN_BASE_URL_CLOSE;
const axiosInstance = createAxiosInstance(apiUrl);

const dashboardData = async (selectedOptions, endpoint, fromDate, toDate) => {
  const { Employee, Company, "Expense Type": ExpenseType } = selectedOptions;
  // //console.log(Employee, Company, ExpenseType);

  if (!fromDate) {
    const today = new Date();
    const fromDateObj = new Date(today);
    fromDateObj.setMonth(today.getMonth() - 6);

    fromDate = formatDate(fromDateObj); // Assuming formatDate is a function to format the date
  }

  if (!toDate) {
    const today = new Date();
    toDate = formatDate(today); // Assuming formatDate is a function to format the date
  }

  // //console.log(fromDate, toDate);

  try {
    const url = `${apiUrl}/${endpoint}/${Employee}/${Company}/${ExpenseType}/${fromDate}/${toDate}`;
    // const url = `${apiUrl}/${endpoint}/${Employee}/${Company}/${ExpenseType}/${from_date}/${to_date}`;

    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    // `Error fetching data from ${endpoint}:`, error;
    throw error;
  }
};

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};
export { dashboardData };
