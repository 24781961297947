import React, { useState, useContext } from "react";
import svgIcons from "../../../services/svgService";
import Input from "./Input/Input";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { handleLogin, decodeJwtToken } from "../../../services/LogIn/LogInApi";
import { toast } from "react-toastify";
import { BounceLoader } from "react-spinners";
import { AuthContext } from "../../../services/AuthContext";

const LogIn = () => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { setIsLoggedIn } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  const handleButtonClick = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!username || !password) {
      setLoading(false);
      toast.warn("Please fill up the necessary fields!", {
        position: "top-right",
        autoClose: 3000,
      });
      return; // Exit the function early
    }
    try {
      const loginResult = await handleLogin(username, password, "auth/login");
      setLoading(false);
      const status = loginResult.data["status"];
      const result = loginResult.data["result"];

      if (status === "OK") {
        const isAdmin =
          result &&
          status === "OK" &&
          decodeJwtToken(result.access_token).roles.some(
            (role) => role.name === "Admin"
          );

        const message = isAdmin
          ? loginResult.data["message"]
          : "The user is not an admin";
        const messageType = isAdmin ? "success" : "error";

        toast[messageType](message, {
          position: "top-right",
          autoClose: 3000,
        });
        if (isAdmin) {
          localStorage.setItem("isLogged", true);
          setIsLoggedIn(true);
          navigate("/dashboard");
        }
      } else {
        toast.error(loginResult.data["message"], {
          position: "top-right",
          autoClose: 3000,
        });
      }
    } catch (error) {
      // Handle network errors
      setLoading(false);
      const errorMessage = error.response?.data?.message;
      if (errorMessage) {
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
        });
      } else {
        // Handle the error when response or data or message is not available
        toast.error(error.message, {
          position: "top-right",
          autoClose: 3000,
        });
      }
    }
  };

  return (
    <>
      {loading && (
        <div className="loader-container">
          <BounceLoader color={"#211e1e1f"} loading={loading} size={150} />
        </div>
      )}
      <div className="row login-container m-0">
        <div className="login-background">
          <div
            className="white-logo"
            dangerouslySetInnerHTML={{ __html: svgIcons.whiteLogo }}
          />
          {/* <div className="ellipse-1"></div>
          <div className="ellipse-2"></div>
          <div className="ellipse-3"></div> */}

          <svg
            className="svgo"
            width="861"
            height="1024"
            viewBox="0 0 861 1024"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_502_104"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="861"
              height="1024"
            >
              <rect
                width="861"
                height="1024"
                fill="url(#paint0_linear_502_104)"
              />
            </mask>
            <g mask="url(#mask0_502_104)">
              <circle
                opacity="0.2"
                cx="470.136"
                cy="1203.14"
                r="443.601"
                transform="rotate(15 470.136 1203.14)"
                stroke="#D9D9D9"
                strokeWidth="3"
              />
              <circle
                opacity="0.2"
                cx="342.136"
                cy="1131.14"
                r="443.601"
                transform="rotate(15 342.136 1131.14)"
                stroke="#D9D9D9"
                strokeWidth="3"
              />
              <path
                opacity="0.2"
                d="M1018.59 1349.12C955.319 1585.26 712.187 1725.3 475.538 1661.89C238.889 1598.48 98.3472 1355.64 161.621 1119.5C224.896 883.355 468.028 743.321 704.677 806.731C941.326 870.141 1081.87 1112.98 1018.59 1349.12Z"
                stroke="#D9D9D9"
                strokeWidth="3"
              />
            </g>
            <defs>
              <linearGradient
                id="paint0_linear_502_104"
                x1="430.5"
                y1="0"
                x2="430.5"
                y2="1024"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#85C2FA" />
                <stop offset="1" stopColor="#25B2EB" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <form className="login-form validate-form d-flex">
          <div className="col-md-12 form-wrap d-flex justify-content-center  flex-column">
            <div className="form-blue-logo">
              <div
                className=""
                dangerouslySetInnerHTML={{ __html: svgIcons.logo }}
              />
            </div>
            <div className="form-title">
              <p className="form-big-title mb-2">Hello again!</p>
              <p className="form-small-title">Welcome Back</p>
            </div>
            <Input
              logo={svgIcons.email}
              type="text"
              placeholder="Email Address"
              className="mb-3"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <Input
              logo={svgIcons.password}
              type="password"
              placeholder="Your Password"
              className="mb-2"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="form-group d-flex justify-content-between">
              <div className="d-flex align-items-center"></div>
              <div>
                <Link to="/password-reset" className="forgot-title">
                  Set/Reset Password?
                </Link>
              </div>
            </div>
            <div className="form-group form-group-btn">
              <button
                type="submit"
                onClick={handleButtonClick}
                className="btn my-4"
              >
                Log in
              </button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default LogIn;
