import React, { useState, useMemo, useEffect } from "react";
import svgIcons from "../../../../services/svgService";
import { AllList } from "../../../../services/Employee/EmployeeListApi";
import { AgGridReact } from "ag-grid-react";
import Update from "../../../CommonComponents/Modal/Update";
import { alldropdownroot } from "../../../../services/Dropdown/DropdownApi";
import { BounceLoader } from "react-spinners";
import { toast } from "react-toastify";
import DeleteResponse from "../../../CommonComponents/Modal/DeleteResponse";
import EmployeeBlock from "../../../CommonComponents/Modal/EmployeeBlock";

export default function List({ shouldUpdateList }) {
  const [allList, setAllList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowId, setrowId] = useState(false);
  const [data, setData] = useState();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState(null);

  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false); // State to control the visibility of the DeleteResponse modal
  const user_id = localStorage.getItem("user_id");

  const openModal = (id, firstName, lastName, role) => {
    setrowId(id);
    setIsModalOpen(true);
    setFirstName(firstName);
    setLastName(lastName);
    setRole(role);
    //console.log(firstName, lastName);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Function to handle delete click
  const openDeleteModal = (id) => {
    setrowId(id); // Set the selected employee ID
    setIsDeleteModalOpen(true); // Open the DeleteResponse modal
  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setrowId(null); // Reset the selected employee ID when the modal is closed
  };

  // Function to handle block click
  const openBlockModal = (data) => {
    setData(data); // Set the selected employee ID
    setIsBlockModalOpen(true); // Open the DeleteResponse modal
  };

  const closeBlockModal = () => {
    setIsBlockModalOpen(false);
    setrowId(null); // Reset the selected employee ID when the modal is closed
  };

  const fetchDataFromApi = async () => {
    setLoading(true);
    //console.log("ye");
    try {
      // Fetch data from the first API
      const rootResponse = await alldropdownroot("dropdown/item");
      const newRootData = rootResponse.data["result"];

      // Process the data from the first API
      const idToNameMap = newRootData.reduce((map, { id, name }) => {
        map[id] = name;
        return map;
      }, {});

      // Fetch data from the second API using the processed data from the first API
      const result = await AllList("users");
      const filteredDesignationOptions = result.data.result.filter(
        (item) => item.action_type !== "DELETED"
      );
      const newData = filteredDesignationOptions.map((user) => ({
        ...user,
        username: `${user.first_name} ${user.last_name}`, // Combine firstname and lastname
        designation: idToNameMap[user.designation], // Use the processed data from the first API
      }));

      // Update state with the data from the second API
      setAllList(newData);
      setLoading(false);
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 3000,
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    // Call the function to initiate the data fetching
    fetchDataFromApi();
  }, [shouldUpdateList]); // Dependency array is empty to ensure this effect runs only once after the initial render

  const ActionIcon = (params) => {
    const icons = ["edit"]; // Define additional icons
    const EmployeeID = params.data.id;
    const firstName = params.data.first_name;
    const lastName = params.data.last_name;
    const isActive = params.data.is_active;
    const role = params.data.roles[0];

    // Define main icon based on the value of is_active
    const mainIcon = isActive ? "cross" : "right";
    const mainIconTitle = isActive ? "Block" : "Unblock";

    // Function to handle click on activate or deactivate icon
    const handleToggleClick = () => {
      openBlockModal(params.data);
    };

    // Function to handle click on delete icon
    const handleDeleteClick = () => {
      openDeleteModal(EmployeeID); // Call the handleDeleteClick function passing the employee ID
    };

    // Function to handle click on edit icon
    const handleEditClick = () => {
      //console.log(EmployeeID);
      // Implement edit functionality here
      openModal(EmployeeID, firstName, lastName, role);
    };

    // Map each icon to its corresponding title
    const iconTitles = {
      cross: "Unblock",
      edit: "Update",
      right: "Block",
    };

    return (
      <div className="m-expense_logo d-flex w-100 justify-content-evenly">
        {EmployeeID !== user_id && (
          <div
            className="cursor-pointer"
            dangerouslySetInnerHTML={{ __html: svgIcons[mainIcon] }}
            onClick={handleToggleClick}
            title={mainIconTitle}
          />
        )}
        {EmployeeID === user_id && (
          <div
            dangerouslySetInnerHTML={{ __html: svgIcons["redDisabled"] }}
            style={{ cursor: "not-allowed" }}
          />
        )}

        {/* Render additional icons */}
        {icons.map((icon, index) => (
          <div
            key={index}
            className="cursor-pointer"
            dangerouslySetInnerHTML={{ __html: svgIcons[icon] }}
            onClick={
              icon === "edit"
                ? handleEditClick
                : icon === "delete"
                ? handleDeleteClick
                : null
            }
            title={iconTitles[icon]} // Set title based on the icon
          />
        ))}
      </div>
    );
  };

  const colDefs = [
    {
      field: "username",
      headerName: "Employee Name",
      filter: true,
      filterParams: {
        filterPlaceholder: "Search...",
      },
    },
    {
      field: "first_name",
      headerName: "first_name",
      hide: true,
    },
    {
      field: "last_name",
      headerName: "last_name",
      hide: true,
    },
    {
      field: "is_active",
      headerName: "is_active",
      hide: true,
    },
    {
      field: "id",
      headerName: "Employee ID",
      filter: true,
      sortable: true,
      flex: 1,
    },
    { field: "designation", headerName: "Designation", filter: true },
    {
      field: "role",
      headerName: "Role",
      filter: true,
      valueGetter: (params) => {
        return params?.data?.roles?.map((role) => role.name).join(", ");
      },
    },
    {
      field: "email",
      headerName: "Email",
      sortable: true,
      flex: 1,
      filter: true,
    },
    {
      field: "Action",
      headerName: "Action",
      cellRenderer: ActionIcon,
    },
  ];

  const gridOptions = {
    suppressMenuHide: true,
    localeText: {
      noRowsToShow: "No records found.",
    },
  };
  const paginationPageSizeSelector = useMemo(() => {
    return [10, 20, 50];
  }, []);

  const pageSize = 10;

  const handleUpdateEmployeeList = () => {
    //console.log("updated employee");
    fetchDataFromApi();
  };

  return (
    <>
      {loading && (
        <div className="loader-container">
          <BounceLoader color={"#211e1e1f"} loading={loading} size={150} />
        </div>
      )}
      <div
        className="container-fluid mt-4 mb-5 " // applying the grid theme
      >
        <div className="m-expense-table-container employee-table">
          <div
            className="table-wrapper ag-theme-quartz"
            style={{ height: 520 }}
          >
            <AgGridReact
              multiSortKey={"ctrl"}
              gridOptions={gridOptions}
              rowData={allList}
              columnDefs={colDefs}
              pagination={true}
              paginationPageSize={pageSize}
              paginationPageSizeSelector={paginationPageSizeSelector}
              getRowStyle={(params) => {
                return {
                  background:
                    params.node.rowIndex % 2 === 0
                      ? ""
                      : "rgba(245, 245, 245, 1)",
                };
              }}
            />
          </div>
        </div>
      </div>
      <Update
        onUpdateEmployeeList={handleUpdateEmployeeList}
        rowId={rowId}
        firstName={firstName}
        lastName={lastName}
        isModalOpen={isModalOpen}
        onClose={closeModal}
        roles={role}
      />
      <DeleteResponse
        isModalOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        employeeId={rowId} // Pass the selected employee ID to the DeleteResponse modal
        onUpdateEmployeeList={handleUpdateEmployeeList}
      />
      <EmployeeBlock
        isBlockModalOpen={isBlockModalOpen}
        onClose={closeBlockModal}
        data={data}
        onUpdateEmployeeList={handleUpdateEmployeeList}
      />
    </>
  );
}
