import svgIcons from "../../../services/svgService";
// reactstrap components
import { DropdownMenu, DropdownItem, Media } from "reactstrap";
import { useNavigate } from "react-router-dom";
import React, { useContext } from "react";
import { AuthContext } from "../../../services/AuthContext";

const DropDown = () => {
  const navigate = useNavigate(); // Initialize the navigate function
  const { setIsLoggedIn } = useContext(AuthContext);

  const handleLogout = () => {
    // Clear localStorage values
    // localStorage.clear();
    // Navigate to login page
    localStorage.removeItem("id");
    localStorage.removeItem("user_id");
    setIsLoggedIn(false);
    navigate("/login", { replace: true });
  };

  return (
    <DropdownMenu className="dropdown-menu-arrow">
      <DropdownItem className="noti-title" header tag="div">
        <Media className="align-items-center">
          <span className="avatar avatar-sm rounded-circle">
            <img alt="..." src={require("../../../assets/images/user.png")} />
          </span>
          <span className="">Profile</span>
        </Media>
      </DropdownItem>
      <DropdownItem divider />

      <DropdownItem href="/password-reset" className="mb-5">
        <span
          className="d-flex dropdown-svg"
          dangerouslySetInnerHTML={{ __html: svgIcons.changePassword }}
        />
        <span>Change Password</span>
      </DropdownItem>
      <DropdownItem divider />
      <DropdownItem onClick={handleLogout}>
        <span
          className="d-flex dropdown-svg"
          dangerouslySetInnerHTML={{ __html: svgIcons.logout }}
        />
        <span>Logout</span>
      </DropdownItem>
    </DropdownMenu>
  );
};

export default DropDown;
