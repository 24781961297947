import React, { useContext } from "react";
import LogIn from "../LayoutComponents/LogIn/LogIn";
import { AuthContext } from "../../services/AuthContext";
import { Navigate } from "react-router-dom";

const LogInPage = () => {
  const { isLoggedIn } = useContext(AuthContext);

  if (isLoggedIn) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <>
      <LogIn />
    </>
  );
};

export default LogInPage;
