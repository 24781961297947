import React from "react";
import Dashboard from "./../LayoutComponents/Dashboard/Dashboard";
import AdminNavbar from "../CommonComponents/Navbars/AdminNavbar";

export default function DashboardPage() {
  return (
    <>
      <AdminNavbar />
      <Dashboard />
    </>
  );
}
