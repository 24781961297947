import React, { useState } from "react";
import List from "./List/List";
import Report from "./Report/Report";

const Archived = (props) => {
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [company, setCompany] = useState("");
  const [employee, setEmployee] = useState("");
  const [payment, setPayment] = useState("");

  const handleReportDates = (
    startDate,
    endDate,
    company,
    employee,
    payment
  ) => {
    setFromDate(startDate);
    setToDate(endDate);
    setCompany(company);
    setEmployee(employee);
    setPayment(payment);
  };

  return (
    <>
      <div className="header pb-8 py-5 py-lg-8   align-items-center"></div>
      {/* Show Report */}
      <div className="container-fluid mt-4">
        <div className="card review-card">
          <div className="card-body">
            <div className="row">
              <Report onReportDatesChange={handleReportDates} />
            </div>
          </div>
        </div>
      </div>
      {/* Pending List */}
      <List
        fromDate={fromDate}
        toDate={toDate}
        company={company}
        employee={employee}
        payment={payment}
      />
      {/* Pass data as props */}
    </>
  );
};

export default Archived;
