import createAxiosInstance from "../axiosConfig";
const apiUrl = process.env.REACT_APP_MAIN_BASE_URL_CLOSE;
const axiosInstance = createAxiosInstance(apiUrl);

const dropdownroot = async (endpoint) => {
  try {
    const url = `/${endpoint}`;
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    //console.error(`Error fetching data from ${endpoint}:`, error);
    throw error;
  }
};

const alldropdownroot = async (endpoint) => {
  try {
    const url = `${apiUrl}/${endpoint}`;
    // //console.log(url);
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    //console.error(`Error fetching data from ${endpoint}:`, error);
    throw error;
  }
};

const dropdownChild = async (endpoint, id) => {
  try {
    const url = `/${endpoint}/${id}`;
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    //console.error(`Error fetching data from ${endpoint}:`, error);
    throw error;
  }
};

const dropdownChildDelete = async (endpoint, id) => {
  try {
    const url = `/${endpoint}/${id}`;
    const response = await axiosInstance.delete(url);
    return response;
  } catch (error) {
    //console.error(`Error fetching data from ${endpoint}:`, error);
    throw error;
  }
};

const addDropdownChild = async (rootEndpoint, dropdownName, rootID) => {
  try {
    const url = `/${rootEndpoint}`;
    const postData = {
      name: dropdownName,
      parent_id: 0,
      sort: 1,
      type_id: rootID,
    };
    const response = await axiosInstance.post(url, postData);
    return response;
  } catch (error) {
    //console.error(`Error posting data to ${rootEndpoint}:`, error);
    throw error;
  }
};

export {
  dropdownroot,
  dropdownChild,
  dropdownChildDelete,
  addDropdownChild,
  alldropdownroot,
};
