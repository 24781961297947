import createAxiosInstance from "../axiosConfig";
const apiUrl = process.env.REACT_APP_MAIN_BASE_URL_CLOSE;
const axiosInstance = createAxiosInstance(apiUrl);

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

const fetchFromToDate = (fromDate, toDate) => {
  if (!fromDate || !toDate) {
    const today = new Date();
    const fromDateObj = new Date(today);
    fromDateObj.setMonth(today.getMonth() - 6);

    const formattedFromDate = formatDate(fromDateObj);
    const formattedToDate = formatDate(today);

    fromDate = fromDate || formattedFromDate;
    toDate = toDate || formattedToDate;
  }
  return { fromDate, toDate };
};

const fetchFromToDateForArchive = (fromDate, toDate) => {
  if (!fromDate || !toDate) {
    const today = new Date();
    const fromDateObj = new Date(today);
    fromDateObj.setMonth(today.getMonth() - 6);

    const formattedFromDate = formatDate(fromDateObj);
    const formattedToDate = formatDate(today);

    fromDate = fromDate || formattedFromDate;
    toDate = toDate || formattedToDate;
  }
  return { fromDate, toDate };
};

const PendingList = async (endpoint, field, value, fromDate, toDate) => {
  const { fromDate: from, toDate: to } = fetchFromToDate(fromDate, toDate);
  try {
    const url = `${endpoint}/${field}/where-value/${value}/from-date/${from}/to-date/${to}`;
    const response = await axiosInstance.get(url);
    // //console.log(response, url);

    return response;
  } catch (error) {
    // `Error fetching data from ${endpoint}:`, error;
    throw error;
  }
};

const ArchivedList = async (endpoint, fromDate, toDate) => {
  const { fromDate: from, toDate: to } = fetchFromToDateForArchive(
    fromDate,
    toDate
  );

  try {
    const url = `${endpoint}/${from}/${to}`;
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    // `Error fetching data from ${endpoint}:`, error;
    throw error;
  }
};

const comment = async (endpoint, id) => {
  try {
    const url = `${endpoint}/${id}`;
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    // `Error fetching data from ${endpoint}:`, error;
    throw error;
  }
};

const confirmedAmount = async (amount, id, endpoint) => {
  try {
    const url = `${apiUrl}/${endpoint}/${id}`;
    const postData = {
      amount: amount,
    };
    const response = await axiosInstance.post(url, postData);
    return response;
  } catch (error) {
    // `Error posting data to ${endpoint}:`, error;
    throw error;
  }
};

export { PendingList, comment, ArchivedList, confirmedAmount };
