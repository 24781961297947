import React, { useState ,useEffect} from "react";
import svgIcons from "../../../services/svgService";
import { BounceLoader } from "react-spinners";
import { toast } from "react-toastify";
import { DeclineArchived } from "../../../services/PendingList/DeclineArchivedApi";

const Decline = ({ isModalOpen, onClose, rowId, onUpdatePendingList,comment }) => {
  const [declineReason, setDeclineReason] = useState(""); // State to store the decline reason
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setDeclineReason(comment);
  }, [comment]);

  //console.log(rowId);
  if (!isModalOpen) {
    return null;
  }

 

  // Handler for handling the change in textarea input
  const handleInputChange = (event) => {
    setDeclineReason(event.target.value);
  };

  // Handler for handling the confirmation button click
  const handleConfirm = () => {
    // Do something with the declineReason, such as sending it to the server
    //console.log("Decline Reason:", declineReason);
    console.log(declineReason);

    // Check if declineReason is empty
    if (!declineReason.trim()) {
      // Display toast warning
      toast.warning("Please provide a decline reason.", {
        position: "top-right",
        autoClose: 3000,
      });
      return; // Stop further execution
    }

    const fetchDataFromApi = async () => {
      setLoading(true);

      try {
        // Fetch data from the first API
        const rootResponse = await DeclineArchived(
          declineReason,
          rowId,
          "DECLINED",
          "expense/bulk_update"
        );

        //console.log(newRootData);
        toast.success(rootResponse.data["message"], {
          position: "top-right",
          autoClose: 3000,
        });
        onUpdatePendingList();
        setLoading(false);
      } catch (error) {
        toast.error(error.message, {
          position: "top-right",
          autoClose: 3000,
        });
        setLoading(false);
      }
    };
    fetchDataFromApi();

    // Close the modal
    onClose();
    setDeclineReason("");
  };

  return (
    <>
      {loading && (
        <div className="loader-container">
          <BounceLoader color={"#211e1e1f"} loading={loading} size={150} />
        </div>
      )}
      <div className="modal dropdown-add">
        <div className="modal-card col-md-4 bg-white shadow">
          <div className="modal-card-body p-0">
            <div className="row justify-content-center m-0 sftp-card">
              <div className="col-md-12 p-0">
                <div
                  className="d-flex justify-content-end close-btn"
                  onClick={onClose}
                  dangerouslySetInnerHTML={{ __html: svgIcons.Modalcross }}
                />
              </div>
              <div className="col-md-10 p-0 margin-top">
                <div className="sftp-form">
                  <div className="col-lg-12">
                    <div className="form-group mb-4">
                      <textarea
                        type="text"
                        className="form-control form-input text-background"
                        placeholder="Decline reason..."
                        rows={2}
                        style={{
                          backgroundColor: "rgba(245, 245, 245, 0.8)",
                          border: "none",
                        }}
                        value={declineReason} // Bind the value to the state
                        onChange={handleInputChange}
                        />
                    </div>
                    <div className="col-lg-12">
                      <button
                        className="btn review-btn"
                        onClick={handleConfirm}
                      >
                        Confirm
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Decline;
