// ApiContext.js
import React, { createContext, useContext, useState, useEffect } from "react";
import {
  dropdownroot,
  dropdownChild,
  addDropdownChild,
  dropdownChildDelete,
} from "./DropdownApi";

const ApiContext = createContext();

const ApiProvider = ({ children }) => {
  const [rootData, setRootData] = useState([]);
  const [childData, setChildData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const rootResponse = await dropdownroot("dropdown/type");
        const newRootData = rootResponse.data["result"];
        setRootData(newRootData);

        const childDataPromises = newRootData.map(async (rootItem) => {
          const childResponse = await dropdownChild(
            "dropdown/item/by-type",
            rootItem.id
          );
          return childResponse.data["result"];
        });

        const resolvedChildData = await Promise.all(childDataPromises);

        const mergedChildData = resolvedChildData.flat();
        setChildData(mergedChildData);
        // //console.log(childData);

        setLoading(false);
      } catch (error) {
        // "Error fetching data:", error;
      }
    };

    fetchData();
  }, []);

  const addDesignation = async (rootId, designationName) => {
    try {
      const response = await addDropdownChild(
        "dropdown/item",
        designationName,
        rootId
      );
      //console.log("POST request successful:", response.data["result"]);

      const newDesignation = response.data["result"];
      setChildData((prevChildData) => [...prevChildData, newDesignation]);
      //console.log("POST request successful:", newDesignation);
    } catch (error) {
      // "Error in POST request:", error;
    }
  };

  const removeDesignation = async (rootId, childId) => {
    try {
      // Make the API call to remove the designation
      const response = await dropdownChildDelete("dropdown/item", childId);
      // //console.log(response);
      if (response.data["status"] === "OK") {
        const indexToRemove = childData.findIndex(
          (designation) =>
            designation.id === childId && designation.type_id === rootId
        );

        if (indexToRemove !== -1) {
          const updatedChildData = [...childData];
          updatedChildData.splice(indexToRemove, 1);
          setChildData(updatedChildData);
        }
      }
    } catch (error) {
      // "Error removing designation:", error;
    }
  };

  return (
    <ApiContext.Provider
      value={{
        rootData,
        childData,
        loading,
        addDesignation,
        removeDesignation,
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

const useApi = () => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error("useApi must be used within an ApiProvider");
  }
  return context;
};

export { ApiProvider, useApi, ApiContext };
