import React from "react";
import Reset from "../LayoutComponents/PasswordReset/Reset";
import PasswordNavbar from "../CommonComponents/Navbars/PasswordNavbar";

const PassportReset = () => {
  return (
    <>
      <PasswordNavbar />
      <Reset />
    </>
  );
};

export default PassportReset;
