import React from "react";
import Change from "../LayoutComponents/ChangePassword/Change";
import PasswordNavbar from "../CommonComponents/Navbars/PasswordNavbar";

const ChangePassword = () => {
  return (
    <>
      <PasswordNavbar />
      <Change />
    </>
  );
};

export default ChangePassword;
