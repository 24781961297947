import React from "react";
import AuthNavbar from "../CommonComponents/Navbars/AuthNavbar";

export default function Auth() {
  return (
    <>
      <AuthNavbar />
    </>
  );
}
