import React from "react";
import { Route, Routes } from "react-router-dom";
import LogInPage from "../Pages/LogInPage";
import PasswordReset from "../Pages/PasswordResetPage";
import VerifyOtp from "../Pages/VerifyOtpPage";
import ChangePassword from "../Pages/ChangePasswordPage";
import Admin from "../layouts/Admin";
import Auth from "../layouts/Auth";

const Routing = () => {
  // const { isLoggedIn } = useContext(AuthContext);
  // //console.log(isLoggedIn);

  return (
    <Routes>
      <Route path="" element={<LogInPage />}></Route>
      <Route path="/login" element={<LogInPage />}></Route>
      <Route path="/password-reset" element={<PasswordReset />}></Route>
      <Route path="/verify-otp" element={<VerifyOtp />}></Route>
      <Route path="/change-password" element={<ChangePassword />}></Route>
      <Route path="*" element={<Admin />} />
      <Route path="*" element={<Auth />} />

      {/* Routes accessible only when logged in */}
      {/* Routes for authenticated pages */}
    </Routes>
  );
};

export default Routing;
