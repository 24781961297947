import React from "react";
import svgIcons from "../../../services/svgService";

const Response = ({
  isResponseModalOpen,
  closeModal,
  closeAllModals,
  success,
}) => {
  if (!isResponseModalOpen) {
    return null;
  }
  if (!success) {
    closeModal();
  }

  return (
    <div className="modal dropdown-add">
      <div className="modal-card col-md-4 bg-white shadow">
        <div className="modal-card-body p-0">
          <div className="row justify-content-center m-0 sftp-card">
            <div className="col-md-12 p-0">
              <div
                className="d-flex justify-content-end close-btn"
                onClick={closeModal}
                dangerouslySetInnerHTML={{ __html: svgIcons.Modalcross }}
              />
            </div>
            <div className="col-md-10 p-0 margin-top">
              <div className="sftp-form">
                <div className="col-lg-12">
                  <div className="form-group mb-4">
                    <p
                      style={{
                        color: "rgba(73, 83, 106, .7)",
                        textAlign: "center",
                      }}
                    >
                      Invitation has been sent successfully.
                    </p>
                  </div>
                  <div className="col-lg-12 d-flex justify-content-center">
                    <button
                      style={{
                        width: "120px",
                      }}
                      className="btn review-btn"
                      onClick={closeAllModals}
                    >
                      Ok
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Response;
