import createAxiosInstance from "../axiosConfig";
const apiUrl = process.env.REACT_APP_MAIN_BASE_URL_CLOSE;
const axiosInstance = createAxiosInstance(apiUrl);

const DeclineArchived = async (comment, id, status, endpoint) => {
  console.log('OL',comment, id, status, endpoint);
  try {
    const url = `${apiUrl}/${endpoint}`;
    const postData = {
      data: [
        {
          id: id,
          comment: comment,
        },
      ],
      status: status,
    };
    const response = await axiosInstance.post(url, postData);
    return response;
  } catch (error) {
    //console.error(`Error posting data to ${endpoint}:`, error);
    throw error;
  }
};

const DeclineArchivedAll = async (Alldata, status, endpoint) => {
  // console.log(Alldata, status, endpoint);
  try {
    const url = `${apiUrl}/${endpoint}`;
    const postData = {
      data: Alldata,
      status: status,
    };
    const response = await axiosInstance.post(url, postData);
    return response;
  } catch (error) {
    //console.error(`Error posting data to ${endpoint}:`, error);
    throw error;
  }
};

export { DeclineArchived, DeclineArchivedAll };
