import React, { useState } from "react";
import Modal from "../../../CommonComponents/Modal/Modal";

export default function Add({ onUpdateList }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div className="col-sm-6 d-flex add-employee-title">
        <p className="review-title d-flex align-items-center m-0">
          Want to add new Employee?
        </p>
      </div>
      <div className="col-sm-6 d-flex add-employee">
        <button className="btn review-btn" onClick={openModal}>
          Add Employee
        </button>
      </div>
      <Modal
        isModalOpen={isModalOpen}
        onClose={closeModal}
        onUpdateList={onUpdateList}
      />
    </>
  );
}
