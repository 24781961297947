import React, { useState } from "react";
import svgIcons from "../../../../services/svgService";

const Input = ({ logo, type, placeholder, className, value, onChange }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className={`form-group mb-3 ${className}`}>
      <div className="input-group input-group-alternative">
        <div
          className="form-logo border-end-0"
          dangerouslySetInnerHTML={{ __html: logo }}
        />
        <input
          className="form-input form-control text-background"
          placeholder={placeholder}
          type={isPasswordVisible ? "text" : type}
          value={value}
          onChange={onChange}
          required
        />
        {type === "password" && (
          <div
            className="form-logo border-start-0"
            onClick={togglePasswordVisibility}
            dangerouslySetInnerHTML={{
              __html: isPasswordVisible ? svgIcons.showPass : svgIcons.hidePass,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Input;
