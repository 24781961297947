import React from "react";

export default function Card({ svg, cardNumber, cardTitle }) {
  return (
    <>
      <div className="col-md-4 card-container">
        <div className="card dashboard-card">
          <div className="card-body d-flex">
            <div className="overlay d-flex align-items-center">
              <div dangerouslySetInnerHTML={{ __html: svg }} />
            </div>
            <div className="dashboard-card-section">
              <div className="dashboard-card-number">
                <p className="m-0">{cardNumber}</p>
              </div>
              <div className="dashboard-card-title">
                <p>{cardTitle}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
