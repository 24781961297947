import axios from "axios";

const apiUrl = process.env.REACT_APP_SECURITY_BASE_URL_OPEN;

const sendOTP = async (email, endpoint) => {
  try {
    const response = await axios.post(`${apiUrl}/${endpoint}`, {
      email: email,
      otp_length: 4,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const matchOTP = async (email, OTP, endpoint) => {
  try {
    const response = await axios.post(`${apiUrl}/${endpoint}`, {
      email: email,
      otp: OTP,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

const changePassword = async (access_token, newPassword, endpoint) => {
  try {
    const response = await axios.post(`${apiUrl}/${endpoint}`, {
      access_token: access_token,
      new_password: newPassword,
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export { sendOTP, matchOTP, changePassword };
