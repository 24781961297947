import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { BounceLoader } from "react-spinners";
import { matchOTP } from "../../../services/OTP/OtpApi";

const Otp = () => {
  const [values, setValues] = useState(Array(4).fill(""));
  const [loading, setLoading] = useState(false);
  const inputs = useRef(Array(4).fill(null));
  const navigate = useNavigate();
  const { state } = useLocation(); // Access location state
  const email = state ? state.email : null;

  useEffect(() => {
    inputs.current[0].focus();
  }, []);

  const handleKeyUp = (event, index) => {
    if (event.code === "Backspace" && hasNoValue(index)) {
      if (index > 0) inputs.current[index - 1].focus();
    } else if (event.target.value !== "") {
      index < inputs.current.length - 1
        ? inputs.current[index + 1].focus()
        : inputs.current[index].blur();
    }

    setValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = event.target.value;
      return newValues;
    });
  };

  const handleInput = (event, index) => {
    if (event.target.value > 9) {
      event.target.value = event.target.value % 10;
    }
  };

  const handlePaste = (event, index) => {
    event.preventDefault();
    const clipData = event.clipboardData.getData("text/plain").split("");
    fillData(index, clipData);
  };

  const fillData = (index, clipData) => {
    setValues((prevValues) => {
      const newValues = [...prevValues];
      for (let i = index; i < inputs.current.length; i++) {
        const newValue = clipData.shift() || ""; // Use empty string if clipData is undefined
        newValues[i] = newValue;
        inputs.current[i].value = newValue; // Set value directly on the input
      }
      return newValues;
    });
  };

  const hasNoValue = (index) => {
    return !values[index] && values[index] !== 0;
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const OTP = values.join("");
    // console.log(typeof(OTP));
    if (OTP.length < 4) {
      toast.warn("Please fill up all fields!", {
        position: "top-right",
        autoClose: 3000,
      });
      return; // Exit the function early
    }
    // OTP MATCH API INTEGRATION
    let loginResult;
    try {
      setLoading(true);
      loginResult = await matchOTP(email, OTP, "otp/match_otp");
      // //console.log(loginResult);
      setLoading(false);
      const { status, message } = loginResult;
      const toastType = status === "OK" ? "success" : "error";
      // //console.log(message);
      toast[toastType](message, {
        position: "top-right",
        autoClose: 3000,
      });

      if (status === "OK") {
        const { access_token } = loginResult.result;
        navigate("/change-password", { state: { access_token } });
      }
    } catch (error) {
      // Handle network errors
      setLoading(false);
      const errorMessage = error.response?.data?.message;
      if (errorMessage) {
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
        });
      } else {
        // Handle the error when response or data or message is not available
        toast.error(error.message, {
          position: "top-right",
          autoClose: 3000,
        });
      }
    }
  };
  return (
    <>
      {loading && (
        <div className="loader-container">
          <BounceLoader color={"#211e1e1f"} loading={loading} size={150} />
        </div>
      )}
      <div className="centered-div password-reset otp-section">
        <form className="reset validate-form d-flex">
          <div className="col-md-12 form-wrap d-flex justify-content-center flex-column">
            <div className="form-title">
              <p className="form-big-title mb-2">Verify OTP</p>
            </div>
            <div>
              <p className="forgot-title">
                Please enter 4 digit code sent to your email
              </p>
            </div>

            <div className="otp-row mb-2">
              {values.map((value, index) => (
                <input
                  key={index}
                  ref={(input) => (inputs.current[index] = input)}
                  type="number"
                  min="0"
                  max="9"
                  step="1"
                  aria-label={`digit ${index + 1}`}
                  defaultValue={value}
                  onKeyUp={(event) => handleKeyUp(event, index)}
                  onInput={(event) => handleInput(event, index)}
                  onPaste={(event) => handlePaste(event, index)}
                />
              ))}
            </div>

            <div className="form-group d-flex flex-column align-items-center  form-group-btn">
              <button
                className="btn w-100 d-block mb-3"
                style={{ padding: "12px" }}
                onClick={onSubmit}
              >
                Confirm
              </button>
              <Link to="/password-reset" className="back">
                Back
              </Link>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Otp;
