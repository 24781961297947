import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { sendOTP } from "../../../services/OTP/OtpApi";
import { toast } from "react-toastify";
import { BounceLoader } from "react-spinners";

const Reset = () => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Function to handle changes in the input field
  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) {
      toast.warn("Please enter email address!", {
        position: "top-right",
        autoClose: 3000,
      });
      return; // Exit the function early
    }
    // OTP SEND API INTEGRATION
    try {
      setLoading(true);
      const loginResult = await sendOTP(email, "otp/send_otp");
      //console.log(loginResult.status.message);
      setLoading(false);
      const { status, message } = loginResult;
      const toastType = status === "OK" ? "success" : "error";

      toast[toastType](message, {
        position: "top-right",
        autoClose: 3000,
      });

      if (status === "OK") {
        navigate("/verify-otp", { state: { email } });
      }
    } catch (error) {
      // Handle network errors
      setLoading(false);
      const errorMessage = error.response?.data?.message;
      if (errorMessage) {
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
        });
      } else {
        // Handle the error when response or data or message is not available
        toast.error(error.message, {
          position: "top-right",
          autoClose: 3000,
        });
      }
    }
    //console.log("Email:", email);
  };
  return (
    <>
      {loading && (
        <div className="loader-container">
          <BounceLoader color={"#211e1e1f"} loading={loading} size={150} />
        </div>
      )}
      <div className="centered-div password-reset">
        <form className="reset validate-form d-flex">
          <div className="col-md-12 form-wrap d-flex justify-content-center flex-column">
            <div className="form-title">
              <p className="form-big-title mb-2">Set/Reset Password</p>
            </div>
            <div>
              <p className="forgot-title">Reset password in two quick step</p>
            </div>

            <div className="input-group input-group-alternative">
              <input
                className="form-input form-control text-background"
                placeholder="Email address"
                type="text"
                value={email} // Bind the value of the input field to the 'email' state variable
                onChange={handleInputChange} // Call handleInputChange function on input change
                required
              />
            </div>

            <div className="form-group d-flex flex-column align-items-center  form-group-btn">
              <button
                className="btn w-100 d-block mb-3"
                style={{ padding: "12px" }}
                onClick={handleSubmit}
              >
                Send OTP
              </button>
              <Link to="/login" className="back">
                Back
              </Link>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default Reset;
