import { useState } from "react";
import { NavLink as NavLinkRRD } from "react-router-dom";
import { PropTypes } from "prop-types";
import svgIcons from "./../../../services/svgService";
import DropDown from "../DropDown/DropDown";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../services/AuthContext";
import React, { useContext } from "react";

// reactstrap components
import {
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Row,
  Col,
  Media,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
import { decodeJwtToken } from "../../../services/LogIn/LogInApi";
import { useNavigate } from "react-router-dom";

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState();
  const navigate = useNavigate();
  const { setIsLoggedIn } = useContext(AuthContext);

  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <NavItem key={key}>
            <NavLink
              className="d-flex mb-2 align-items-center"
              to={prop.path}
              tag={NavLinkRRD}
              onClick={closeCollapse}
            >
              <span
                className="d-flex me-2"
                dangerouslySetInnerHTML={{ __html: prop.icon }}
              />
              {prop.name}
            </NavLink>
          </NavItem>
        );
      } else return null;
    });
  };

  // creates the links that appear in the last of sidebar
  const createLinksSettings = (routes, handleLogout) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <NavItem key={key}>
            <NavLink
              className="d-flex mb-2 align-items-center"
              to={prop.path}
              tag={NavLinkRRD}
              onClick={prop.name === "Log out" ? handleLogout : closeCollapse}
            >
              <span
                className="d-flex me-2"
                dangerouslySetInnerHTML={{ __html: prop.icon }}
              />
              {prop.name}
            </NavLink>
          </NavItem>
        );
      } else return null;
    });
  };

  const { routes } = props;
  const token = localStorage.getItem("id");
  let username;
  if (token) {
    const decodedToken = decodeJwtToken(token);
    username = decodedToken.first_name + " " + decodedToken.last_name;
  }

  const handleLogout = () => {
    // Clear localStorage
    // localStorage.clear();
    localStorage.removeItem("id");
    localStorage.removeItem("user_id");
    setIsLoggedIn(false);
    // Navigate to login page
    navigate("/login", { replace: true });
  };

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
      id="sidenav-main"
    >
      <button className="navbar-toggler" type="button" onClick={toggleCollapse}>
        <span className="navbar-toggler-icon" />
      </button>
      <Link to="/dashboard">
        <div
          className="m-expense_logo"
          dangerouslySetInnerHTML={{ __html: svgIcons.logo }}
        />
      </Link>

      <Nav className="align-items-center d-md-none">
        <div className="me-2 mb-2 d-flex noti">{username}</div>
        <UncontrolledDropdown nav>
          <DropdownToggle nav>
            <Media className="align-items-center">
              <span className="avatar avatar-sm rounded-circle">
                <img
                  alt="..."
                  src={require("../../../assets/images/user.png")}
                />
              </span>
            </Media>
          </DropdownToggle>
          <DropDown></DropDown>
        </UncontrolledDropdown>
      </Nav>

      <Collapse
        className="justify-content-between"
        navbar
        isOpen={collapseOpen}
      >
        {/* Collapse header */}

        <div className="navbar-collapse-header d-md-none">
          <Row>
            <div
              className="collapse-brand col-6"
              dangerouslySetInnerHTML={{ __html: svgIcons.logo }}
            />
            <Col className="collapse-close" xs="6">
              <button
                className="navbar-toggler"
                type="button"
                onClick={toggleCollapse}
              >
                <span />
                <span />
              </button>
            </Col>
          </Row>
        </div>
        <Nav navbar>{createLinks(routes)}</Nav>
        <hr className="my-3 divider" />

        <Nav navbar>{createLinksSettings(routes, handleLogout)}</Nav>
      </Collapse>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
};

export default Sidebar;
