import React from "react";
import Settings from "../LayoutComponents/Settings/Settings";
import AdminNavbar from "../CommonComponents/Navbars/AdminNavbar";
import { ApiProvider } from "../../services/Dropdown/DropdownContext";

export default function SettingsPage() {
  return (
    <ApiProvider>
      <>
        <AdminNavbar />
        <Settings />
      </>
    </ApiProvider>
  );
}
