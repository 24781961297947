import React from "react";
import svgIcons from "../../../services/svgService";
import { toast } from "react-toastify";
import { deleteEmployee } from "../../../services/Employee/EmployeeListApi";

const DeleteResponse = ({
  isModalOpen,
  onClose,
  employeeId,
  onUpdateEmployeeList,
}) => {
  if (!isModalOpen) {
    return null;
  }
  const onDelete = async () => {
    //console.log(employeeId);
    try {
      const response = await deleteEmployee(employeeId, "users");
      //console.log("Employee deleted successfully");
      toast.success(response.data.message, {
        position: "top-right",
        autoClose: 3000,
      });
      // Trigger the update of the employee list after deletion
      onUpdateEmployeeList();
      onClose();
    } catch (error) {
      const errorMessage = error.response?.data?.message;
      if (errorMessage) {
        toast.error(errorMessage, {
          position: "top-right",
          autoClose: 3000,
        });
      } else {
        // Handle the error when response or data or message is not available
        toast.error(error.message, {
          position: "top-right",
          autoClose: 3000,
        });
      }
    }
  };
  return (
    <div className="modal dropdown-add">
      <div className="modal-card col-md-4 bg-white shadow">
        <div className="modal-card-body p-0">
          <div className="row justify-content-center m-0 sftp-card">
            <div className="col-md-12 p-0">
              <div
                className="d-flex justify-content-end close-btn"
                onClick={onClose}
                dangerouslySetInnerHTML={{ __html: svgIcons.Modalcross }}
              />
            </div>
            <div className="col-md-10 p-0 margin-top">
              <div className="sftp-form">
                <div className="col-lg-12">
                  <div className="form-group mb-4">
                    <p
                      style={{
                        color: "rgba(73, 83, 106, .7)",
                        textAlign: "center",
                      }}
                    >
                      Are you sure you want to delete?
                    </p>
                  </div>
                  <div className="col-lg-12 d-flex justify-content-center">
                    <button
                      style={{
                        width: "120px",
                        color: "rgb(107, 114, 128 ,1)",
                        background: "rgb(255,255,255,1)",
                        border: "1px solid #ccc",
                        padding: "8px",
                      }}
                      className="btn review-btn me-4"
                      onClick={onClose}
                    >
                      No, cancel
                    </button>
                    <button
                      style={{
                        width: "120px",
                        padding: "6px",
                      }}
                      className="btn review-btn"
                      onClick={onDelete}
                    >
                      Yes, I'm sure
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteResponse;
