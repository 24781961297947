import createAxiosInstance from "../axiosConfig";
const apiUrl = process.env.REACT_APP_SECURITY_BASE_URL_CLOSE;
const axiosInstance = createAxiosInstance(apiUrl);

const AllList = async (endpoint) => {
  try {
    const url = `${apiUrl}/${endpoint}`;
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    // `Error fetching data from ${endpoint}:`, error;
    throw error;
  }
};

const Addemployee = async (
  firstName,
  lastName,
  designation,
  email,
  role,
  endpoint
) => {
  const designationNumber = parseInt(designation);

  try {
    const url = `${apiUrl}/${endpoint}`;
    const postData = {
      role_ids: [role],
      username: email,
      first_name: firstName,
      last_name: lastName,
      email: email,
      designation: designationNumber,
      password: "12345678@#",
    };
    const response = await axiosInstance.post(url, postData);
    // //console.log(response.data.result.id);
    console.log(response.data.status);
    console.log(response.data.message[0]);
    if (response.data.status === "FAILED") {
      throw new Error(response.data.message[0]);
    }

    // Check if the first API call was successful before making the second API call
    // if (response.status === 201) {
    //   // Make the second API call
    //   const secondApiUrl = `${apiUrl}/user-roles`;
    //   const secondPostData = {
    //     user: response.data.result.id,
    //     role: role,
    //   };
    //   await axiosInstance.post(secondApiUrl, secondPostData);
    //   // //console.log("Second API response:", secondResponse);
    // } else {
    //   throw new Error("A user with that username already exists.");
    // }
    return response;
  } catch (error) {
    // `Error posting data to ${endpoint}:`, error;
    throw error;
  }
};

const deleteEmployee = async (id, endpoint) => {
  try {
    const url = `${apiUrl}/${endpoint}/${id}`;
    const response = await axiosInstance.delete(url);
    return response;
  } catch (error) {
    // `Error fetching data from ${endpoint}:`, error;
    throw error;
  }
};

const updateEmployee = async (id, data, endpoint) => {
  const { firstName, lastName, role } = data;
  // //console.log(id);
  try {
    const url = `${apiUrl}/${endpoint}/${id}`;
    const postData = {
      first_name: firstName,
      last_name: lastName,
    };
    const response = await axiosInstance.put(url, postData);
    // //console.log(role);
    // Check if the first API call was successful before making the second API call
    if (response.status === 200) {
      // Make the second API call
      const secondApiUrl = `${apiUrl}/user-roles/update_role`;
      const secondPostData = {
        user_id: id,
        role_id: role,
      };
      await axiosInstance.put(secondApiUrl, secondPostData);
      // //console.log("Second API response:", secondResponse);
    } else {
      throw new Error("First API call failed");
    }
    return response;
  } catch (error) {
    // `Error posting data to ${endpoint}:`, error;
    throw error;
  }
};

const BlockUnblock = async (data, endpoint) => {
  const { is_active, id } = data;
  // //console.log(id);
  try {
    const url = `${apiUrl}/${endpoint}/${id}`;
    const postData = {
      is_active: !is_active,
    };
    const response = await axiosInstance.put(url, postData);

    return response;
  } catch (error) {
    // `Error posting data to ${endpoint}:`, error;
    throw error;
  }
};

const getRole = async (endpoint) => {
  try {
    const url = `${apiUrl}/${endpoint}`;
    const response = await axiosInstance.get(url);
    return response;
  } catch (error) {
    // `Error fetching data from ${endpoint}:`, error;
    throw error;
  }
};

const setRole = async (userID, roleID, endpoint) => {
  try {
    const url = `${apiUrl}/${endpoint}`;
    const postData = {
      user: userID,
      role: roleID,
    };
    const response = await axiosInstance.post(url, postData);
    return response;
  } catch (error) {
    // `Error posting data to ${endpoint}:`, error;
    throw error;
  }
};

export {
  AllList,
  Addemployee,
  getRole,
  setRole,
  deleteEmployee,
  updateEmployee,
  BlockUnblock,
};
