import React from "react";
import Archived from "./../LayoutComponents/Archived/Archived";
import AdminNavbar from "../CommonComponents/Navbars/AdminNavbar";
// import Pending from "./../LayoutComponents/Pending/Pending";

export default function ArchivedPage() {
  return (
    <>
      <AdminNavbar />
      <Archived />
    </>
  );
}
