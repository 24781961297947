import React, { useState } from "react";
import svgIcons from "../../../services/svgService";
import { BlockUnblock } from "../../../services/Employee/EmployeeListApi";
import { toast } from "react-toastify";
import { BounceLoader } from "react-spinners";

const EmployeeBlock = ({
  isBlockModalOpen,
  onClose,
  data,
  onUpdateEmployeeList,
}) => {
  const [loading, setLoading] = useState(false);

  if (!isBlockModalOpen) {
    return null;
  }

  const onBlock = async () => {
    setLoading(true);
    try {
      const response = await BlockUnblock(data, "users");
      //console.log(response);
      toast.success(response.data["message"], {
        position: "top-right",
        autoClose: 3000,
      });
      // Trigger the update of the employee list after deletion
      onUpdateEmployeeList();
      onClose();
      setLoading(false);
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 3000,
      });
      setLoading(false);
    }
  };

  return (
    <>
      {loading && (
        <div className="loader-container">
          <BounceLoader color={"#211e1e1f"} loading={loading} size={150} />
        </div>
      )}
      <div className="modal dropdown-add">
        <div className="modal-card col-md-4 bg-white shadow">
          <div className="modal-card-body p-0">
            <div className="row justify-content-center m-0 sftp-card">
              <div className="col-md-12 p-0">
                <div
                  className="d-flex justify-content-end close-btn"
                  onClick={onClose}
                  dangerouslySetInnerHTML={{ __html: svgIcons.Modalcross }}
                />
              </div>
              <div className="col-md-10 p-0 margin-top">
                <div className="sftp-form">
                  <div className="col-lg-12">
                    <div className="form-group mb-4">
                      <p
                        style={{
                          color: "rgba(73, 83, 106, .7)",
                          textAlign: "center",
                        }}
                      >
                        {data.is_active
                          ? "Are you sure you want to block?"
                          : "Are you sure you want to unblock?"}{" "}
                      </p>
                    </div>
                    <div className="col-lg-12 d-flex justify-content-center">
                      <button
                        style={{
                          width: "120px",
                          color: "rgb(107, 114, 128 ,1)",
                          background: "rgb(255,255,255,1)",
                          border: "1px solid #ccc",
                          padding: "8px",
                        }}
                        className="btn review-btn me-4"
                        onClick={onClose}
                      >
                        No, cancel
                      </button>
                      <button
                        style={{
                          width: "120px",
                          padding: "6px",
                        }}
                        className="btn review-btn"
                        onClick={onBlock}
                      >
                        Yes, I'm sure
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeBlock;
