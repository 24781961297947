import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { filterDropdownChildren } from "../../../../services/Dropdown/DropdownFilter";
import { AllList } from "../../../../services/Employee/EmployeeListApi";

const Dropdown = ({ name, onOptionChange }) => {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState("all"); // Initialize selected option state

  const fetchOptions = async (name) => {
    const serviceMap = {
      Company: "Company",
      "Expense Type": "Cost Type",
      Employee: "users",
    };

    try {
      if (name in serviceMap) {
        if (name === "Employee") {
          const result = await AllList(serviceMap[name]);
          const filteredEmployee = result.data.result.filter(
            (item) => item.action_type !== "DELETED"
          );
          setOptions(filteredEmployee);
        } else {
          const { filteredDesignationOptions } = await filterDropdownChildren(
            serviceMap[name]
          );
          setOptions(filteredDesignationOptions);
        }
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    fetchOptions(name);
  }, [name]);

  // Event handler to handle option change
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    onOptionChange(name, event.target.value); // Call the callback function with selected data
  };

  return (
    <>
      <div className="col-4">
        <div className="option-label">{name}</div>
        <select value={selectedOption} onChange={handleOptionChange}>
          <option value="all">All</option>
          {options &&
            options
              .filter((option) => {
                if (name === "Company" || name === "Expense Type") {
                  return option.name && option.id;
                } else if (name === "Employee") {
                  return option.first_name && option.last_name;
                }
                return false;
              })
              .map((option) => (
                <option key={option.id} value={option.id}>
                  {name === "Company" || name === "Expense Type"
                    ? `${option.name}`
                    : `${option.first_name} ${option.last_name}`}
                </option>
              ))}
        </select>
      </div>
    </>
  );
};

export default Dropdown;
